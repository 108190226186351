import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";
import { ArrowDownUp } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { configSelector } from "app/redux/slides/config.slide";
import { currencySelector } from "app/redux/slides/user.slide";
import { CurrencyExchange } from "app/models/User";

interface DefaultProps {
  value?: number;
  adaptPrice?: number;
  isHideAdapt?: boolean;
  showZero?: boolean;
  hiddenValue?: boolean;
  hideCurrency?: boolean;
  maximumFractionDigits?: number;
  currencyExchange?: CurrencyExchange;
  adapted?: boolean;
}
const numberFormat = (
  value: any,
  hideCurrency: boolean = false,
  maximumFractionDigits: number = 2,
  isoCode: string,
  exchangeRate: number,
  shopLocale: string = "de-DE"
) => {
  const exchangeValue: any = value * exchangeRate;

  return new Intl.NumberFormat(shopLocale, {
    style: hideCurrency ? "decimal" : "currency",
    currency: isoCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: maximumFractionDigits,
  }).format(exchangeValue.toFixed(4));
};

const CurrencyNumberFormat = (
  value: any,
  hideCurrency: boolean = false,
  maximumFractionDigits: number = 2,
  isoCode: string,
  exchangeRate: number,
  shopLocale: string = "de-DE",
  currencyExchange?: CurrencyExchange
) => {
  const currency = useSelector(currencySelector);
  let currenctExchangeRate = currency.exchangeRate;
  if (
    currencyExchange &&
    exchangeRate &&
    currencyExchange.isoCode == currency.isoCode
  ) {
    currenctExchangeRate = exchangeRate;
  }

  const exchangeValue: any = value * currenctExchangeRate;

  return new Intl.NumberFormat(shopLocale, {
    style: hideCurrency ? "decimal" : "currency",
    currency: isoCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: maximumFractionDigits,
  }).format(exchangeValue.toFixed(4));
};

const CurrencyFormat = (props: DefaultProps) => {
  const { t } = useTranslation();
  const currency = useSelector(currencySelector);
  const {
    value = null,
    adaptPrice = null,
    isHideAdapt = false,
    showZero,
    hiddenValue = false,
    hideCurrency = false,
    maximumFractionDigits = 2,
    currencyExchange,
    adapted = false
  } = props;

  const currenctExchangeRate =
    currencyExchange && currencyExchange.exchangeRate
      ? currencyExchange.exchangeRate
      : currency.exchangeRate;
  const displayIsoCode =
    currencyExchange && currencyExchange?.isoCode
      ? currencyExchange?.isoCode
      : currency.isoCode;

  const symbol =
    currencyExchange && currencyExchange?.symbol
      ? currencyExchange?.symbol
      : currency.symbol;

  let displayLocale = "de-DE";

  if (displayIsoCode === "CZK") {
    displayLocale = "cs-CZ";
  }
  if(!hiddenValue && showZero && (value === 0 || adaptPrice === 0)) return <>0 {symbol}</>
  if (hiddenValue || (!value && adaptPrice === null)) return <>-- {symbol}</>;
  if (adaptPrice === 0)
    return (
      <>
        {numberFormat(
          0,
          hideCurrency,
          maximumFractionDigits,
          displayIsoCode,
          currenctExchangeRate,
          displayLocale
        )}{" "}
      </>
    );
  if (value !== null && adaptPrice === null)
    return (
      <>
        {numberFormat(
          value,
          hideCurrency,
          maximumFractionDigits,
          displayIsoCode,
          currenctExchangeRate,
          displayLocale
        )}{" "}
      </>
    );
  if (value == null && adaptPrice !== null)
    return (
      <>
        {numberFormat(
          adaptPrice,
          hideCurrency,
          maximumFractionDigits,
          displayIsoCode,
          currenctExchangeRate,
          displayLocale
        )}{" "}
      </>
    );

  return (
    <>
      {!isHideAdapt && (
        <span className="">
          {value !== null &&
            adaptPrice !== null &&
            numberFormat(
              value,
              hideCurrency,
              maximumFractionDigits,
              displayIsoCode,
              currenctExchangeRate,
              displayLocale
            ) !=
              numberFormat(
                adaptPrice,
                hideCurrency,
                maximumFractionDigits,
                displayIsoCode,
                currenctExchangeRate,
                displayLocale
              ) && (
              <Popover
                placement="left"
                content={
                  <>
                    <p className="mb-2 fw-bold">{t("originalPrice")}</p>
                    {numberFormat(
                      value,
                      hideCurrency,
                      maximumFractionDigits,
                      displayIsoCode,
                      currenctExchangeRate,
                      displayLocale
                    )}
                  </>
                }
                trigger="hover"
              >
                <span className="d-flex justify-content-end align-items-center fw-bold">
                  <>
                    <ArrowDownUp />
                    &nbsp;
                  </>
                  {numberFormat(
                    adaptPrice,
                    hideCurrency,
                    maximumFractionDigits,
                    displayIsoCode,
                    currenctExchangeRate,
                    displayLocale
                  )}
                </span>
              </Popover>
            )}
          {value !== null &&
            (adaptPrice === null ||
              numberFormat(
                value,
                hideCurrency,
                maximumFractionDigits,
                displayIsoCode,
                currenctExchangeRate,
                displayLocale
              ) ==
                numberFormat(
                  adaptPrice,
                  hideCurrency,
                  maximumFractionDigits,
                  displayIsoCode,
                  currenctExchangeRate,
                  displayLocale
                )) &&
            numberFormat(
              value,
              hideCurrency,
              maximumFractionDigits,
              displayIsoCode,
              currenctExchangeRate,
              displayLocale
            )}
        </span>
      )}
      {isHideAdapt &&
        numberFormat(
          adaptPrice || value,
          hideCurrency,
          2,
          displayIsoCode,
          currenctExchangeRate,
          displayLocale
        )}
    </>
  );
};

export default CurrencyFormat;
