export const routers = {
    HOME: '/',
    PROJECTS: '/projects',
    PROJECTS_ARCHIVE: '/archive',
    PROJECTS_GROUP1: '/drafts',
    PROJECTS_GROUP2: '/waiting-for-confirming',
    PROJECTS_SELLER_GROUP2: '/sent-offers',
    PROJECTS_BUYER_GROUP2_SUB1: '/requested-offers',
    PROJECTS_BUYER_GROUP2_SUB2: '/received-offers',
    PROJECTS_GROUP3: '/completed',
    PROJECTS_GROUP4: '/cancelled',
    REGISTER: '/register',
    CUSTOMERS: '/customers',
    SETTINGS: '/settings',
    ACCOUNT_SETTINGS: '/account-settings',
    MATERIAL_SETTINGS: '/material-settings',
    MACHINE_SETTINGS: '/machine-settings',
    SURFACE_TREATMENT_SETTINGS: '/surface-treatment-settings',
    COMPLIANCE_SETTINGS: '/compliance-settings',
    QUICK_SETTINGS: '/quick-settings',
    SELF_PROJECTS: '/my-projects',
    SELF_PROJECTS_DRAFTS: '/self-projects/drafts',
    SELF_PROJECTS_DISPATCHED_OFFERS: '/self-projects/dispatched-offers',
    SELF_PROJECTS_ARCHIVE: '/self-projects/archive',
}