import { RcFile } from "antd/es/upload";
import { UserRole } from "app/models/UserRole";

const jsonToFormData = (data: any) => {
  const formData = new FormData();
  Object.keys(data).map((key) => {
    if (data[key] === null || data[key] === undefined) return false;
    if (typeof data[key] === "object" && !(data[key] instanceof File) && !Array.isArray(data[key])) {
      data[key] = JSON.stringify(data[key]);
    }
    formData.append(key, data[key]);
  });
  return formData;
};

const getTrimmedData = (data: any) => {
  const obj = { ...data };
  if (obj && typeof obj === "object") {
    Object.keys(obj).map((key) => {
      if (typeof obj[key] === "object") {
        getTrimmedData(obj[key]);
      } else if (typeof obj[key] === "string") {
        obj[key] = obj[key].trim().replace(/\s+/g, " ");
      }
    });
  }
  return obj;
};

const triggerTypeNumber = (event: any) => {
  if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
    event.preventDefault();
  }
};

const parseFileName = (fileName: string) => {
  return {
    name: fileName.substring(0, fileName.lastIndexOf("."))?.toLowerCase(),
    ext: fileName.split(".").pop()?.toLowerCase()
  }
}

export const getSupportMail = () => {
  switch (process.env.REACT_APP_BRAND) {
    case "ensinger":
      return "tecapart.cz@ensingerplastics.com"
    default:
      return "support@gocad.de"
  }
}

const waitForSeconds = (seconds: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000);
  });
};

const isDxf = (fileName?: string) => {
  return !!fileName && fileName.toLowerCase().indexOf(".dxf") > -1;
};
const isDwg = (fileName?: string) => {
  return !!fileName && fileName.split(".").pop()?.toLowerCase() === "dwg";
};
const isStp = (fileName?: string) => {
  return (
    !!fileName &&
    (fileName.toLowerCase().indexOf(".step") > -1 ||
      fileName.toLowerCase().indexOf(".stp") > -1)
  );
};

export const batchUploadFiles = (files: RcFile[]) => {
  let fData: any = {};
  files?.map((f) => {
    const file: any = new File([f], f.name.replaceAll(",", "_"));
    file.uid = f.uid;
    const p = Utils.parseFileName(f.name);
    const name = p.name;
    const ext = p.ext;
    switch (ext) {
      case "pdf":
        fData[name] = {
          ...fData[name],
          pdfFile: file,
        };
        break;
      case "step":
      case "stp":
      case "dxf":
      case "dwg":
      case "ipt":
      case "jt":
      case "pvz":
      case "edz":
      case "prt":
      case "asm":
      case "sldasm":
      case "sldprt":
      case "model":
      case "catpart":
      case "catproduct":
      case "3ds":
      case "prj":
      case "par":
      case "psm":
      case "x_t":
      case "sab":
      case "geo":
        fData[name] = {
          ...fData[name],
          cadFile: file,
        };
        break;
    }
  });
  const data = Object.keys(fData).map((key) => fData[key]);
  return {
    data: data.filter((f: any) => !!f.cadFile),
    pdfFiles: data.filter((f: any) => !f.cadFile).map((f) => f.pdfFile),
  };
};

export const getRouterByRole = (role: UserRole) => role.replace("ROLE_", "").toLowerCase();


const Utils = {
  jsonToFormData,
  getTrimmedData,
  triggerTypeNumber,
  parseFileName,
  waitForSeconds,
  isDxf,
  isDwg,
  isStp,
};

export default Utils;
