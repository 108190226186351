function CadFileIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="#E2E5E7"
        d="M128 0c-17.6 0-32 14.4-32 32v448c0 17.6 14.4 32 32 32h320c17.6 0 32-14.4 32-32V128L352 0H128z"
      ></path>
      <path
        fill="#B0B7BD"
        d="M384 128h96L352 0v96c0 17.6 14.4 32 32 32z"
      ></path>
      <path fill="#CAD1D8" d="M480 224L384 128 480 128z"></path>
      <path
        fill="#50BEE8"
        d="M416 416c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V256c0-8.8 7.2-16 16-16h352c8.8 0 16 7.2 16 16v160z"
      ></path>
      <g fill="#FFF">
        <path d="M85.648 339.088c0-24.688 15.488-45.92 44.912-45.92 11.12 0 19.952 3.328 29.296 11.392 3.456 3.184 3.824 8.832.368 12.4-3.456 3.056-8.688 2.688-11.76-.384-5.248-5.504-10.624-7.024-17.904-7.024-19.696 0-29.168 13.952-29.168 29.552 0 15.872 9.344 30.448 29.168 30.448 7.28 0 14.064-2.944 19.952-8.192 3.968-3.056 9.472-1.536 11.76 1.536 2.048 2.816 3.072 7.552-1.408 12.032-8.96 8.32-19.696 9.984-30.32 9.984-30.944 0-44.896-21.12-44.896-45.824zM181.056 384c-4.096-2.304-6.656-6.912-4.096-12.288l36.704-71.76c3.456-6.784 12.672-7.024 15.872 0l36.064 71.76c5.248 9.984-10.24 17.904-14.832 7.936l-5.648-11.264h-47.2l-5.504 11.264c-2.032 4.352-6.752 5.264-11.36 4.352zm55.008-32.48l-14.448-31.616-15.728 31.616h30.176zM289.264 384c-4.224 0-8.832-2.304-8.832-7.92v-72.672c0-4.608 4.608-7.936 8.832-7.936h29.296c58.464 0 57.184 88.528 1.152 88.528h-30.448zm8.064-72.928v57.312h21.232c34.544 0 36.08-57.312 0-57.312h-21.232z"></path>
      </g>
      <path
        fill="#CAD1D8"
        d="M400 432H96v16h304c8.8 0 16-7.2 16-16v-16c0 8.8-7.2 16-16 16z"
      ></path>
    </svg>
  );
}

export default CadFileIcon;
