export enum MaterialShapeType {
    RECTANGULAR,
    HEXAGONAL,
    ROUND,
    HALF_ROUND,
    TUBE_RECTANGULAR,
    TUBE_ROUND,
    PROFILE_I,
    PROFILE_L,
    PROFILE_T,
    PROFILE_U,
    SHEET
}