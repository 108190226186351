const VATNoIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#000"
      d="M3.911 10 .59 2.3h1.925l2.904 6.82H4.285L7.233 2.3h1.771L5.671 10h-1.76Zm4.467 0 3.432-7.7h1.76l3.443 7.7h-1.87l-2.816-6.798h.704L10.204 10H8.378Zm1.716-1.65.473-1.353h3.96l.484 1.353h-4.917ZM19.02 10V3.752h-2.464V2.3h6.71v1.452h-2.464V10H19.02ZM3.46 21v-7.7h1.474l4.543 5.544h-.715V13.3h1.76V21H9.059l-4.554-5.544h.715V21H3.46Zm11.579.088c-.631 0-1.192-.132-1.683-.396a3.082 3.082 0 0 1-1.155-1.078 2.985 2.985 0 0 1-.418-1.573c0-.594.139-1.118.418-1.573a2.971 2.971 0 0 1 1.155-1.078c.491-.264 1.052-.396 1.683-.396.623 0 1.18.132 1.672.396.491.257.876.612 1.155 1.067.278.455.418.983.418 1.584 0 .587-.14 1.111-.418 1.573a2.978 2.978 0 0 1-1.155 1.078c-.492.264-1.049.396-1.672.396Zm0-1.408c.286 0 .542-.066.77-.198.227-.132.407-.319.539-.561.132-.25.198-.543.198-.88 0-.345-.066-.638-.198-.88a1.419 1.419 0 0 0-.54-.561 1.505 1.505 0 0 0-.77-.198c-.285 0-.542.066-.77.198-.227.132-.41.319-.55.561-.131.242-.197.535-.197.88 0 .337.066.63.198.88.139.242.322.429.55.561.227.132.484.198.77.198Zm4.976 1.408c-.293 0-.546-.099-.759-.297a1.058 1.058 0 0 1-.308-.781c0-.315.103-.568.308-.759.213-.198.466-.297.76-.297.3 0 .553.099.758.297.206.19.308.444.308.759s-.102.576-.308.781a1.051 1.051 0 0 1-.759.297Z"
    />
  </svg>
)
export default VATNoIcon