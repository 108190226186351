import { Popover } from "antd";
import { Price } from "app/models/Price";
import CurrencyFormat from "../Format/CurrencyFormat";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { QuickSetting } from "app/models/QuickSetting";
import quickSettingsServices from "app/services/quickSetting.service";
import { CurrencyExchange } from "app/models/User";
import { useSelector } from "react-redux";
import { configSelector } from "app/redux/slides/config.slide";

interface DefaultProps {
  price?: Price;
  lableClass?: string;
  currencyExchange?: CurrencyExchange;
}

function SurfaceTreatmentSurcharge(props: DefaultProps) {
  const { price, lableClass, currencyExchange } = props;
  const { t } = useTranslation();
  // const [quickSetting, setQuickSetting] = useState<QuickSetting>();
  const config = useSelector(configSelector);

  // const getQuickSetting = async () => {
  //   try {
  //     const rs = await quickSettingsServices.detail();
  //     setQuickSetting(rs);
  //   } catch (error) {}
  // };

  useEffect(() => {
    // getQuickSetting();
  }, []);

  return (
    <>
      {!!price?.surfaceTreatmentSurcharge && (
        <div className="d-flex">
          <Popover
            overlayStyle={{
              maxWidth: "20vw",
            }}
            placement="top"
            content={
              <>
                {t("price.surfacetreatments.note", {
                  minimumOrderValue: config?.minimumOrderValue,
                  minimumOrderValueForFastDelivery:
                  config?.minimumOrderValueForFastDelivery,
                })}
                <div>
                  <table className="table mb-0 text-white">
                    <thead>
                      <tr>
                        <th scope="col">{t("method")}</th>
                        <th scope="col" className="text-end">
                          {t("price")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {price?.priceSurfaceTreatments &&
                        price?.priceSurfaceTreatments.map((item, index) => (
                          <tr key={`surfacetreatment-${index}`}>
                            <td scope="col">{item.method}</td>
                            <td scope="col" className="text-end">
                              <CurrencyFormat
                                value={item.differenceCost}
                                currencyExchange={currencyExchange}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            }
            trigger="hover"
          >
            <label className={lableClass ?? lableClass}>
              {t("price.surfacetreatments")} <InfoCircleOutlined />:
            </label>
          </Popover>
          <label className="ms-auto">
            <CurrencyFormat
              value={price?.surfaceTreatmentSurcharge}
              currencyExchange={currencyExchange}
            />
          </label>
        </div>
      )}
    </>
  );
}

export default SurfaceTreatmentSurcharge;
