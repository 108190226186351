import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, InputProps, InputRef, Space } from "antd";
import { setFormErrors } from "app/utils/Form";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./inputInlineApi.module.scss";

interface DefaultProps {
  label?: any;
  value?: any;
  name: string;
  error?: string;
  callBack: Function;
  inputProps?: InputProps;
  hiddenLoadingIcon?: boolean;
}

const InputInlineApi = (props: DefaultProps) => {
  const {
    label = "Field",
    value,
    name,
    callBack,
    inputProps,
    hiddenLoadingIcon = false,
  } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tempValue, setTempValue] = useState("");
  const inputRef = useRef<InputRef>(null);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (value) {
      setTempValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (isEdit && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  const handleSetEditMode = () => {
    setIsError(false);
    setIsEdit(true);
  };

  const handleInputBlur = async () => {
    setIsError(false);
    const newValue = form.getFieldValue(`${name}`);
    if (tempValue == newValue || newValue.length == 0) {
      setIsEdit(false);
      return;
    }
    setIsLoading(true);

    try {
      const rs = await callBack(newValue);
      if (rs) {
        setTempValue(rs);
      }
      setIsEdit(false);
    } catch (error: any) {
      if (error?.errorCode) {
        setIsError(true);
        const errorMsg = [{ path: name, message: t(error?.errorCode) }];
        setFormErrors(form, errorMsg);
      }
    }
    setIsLoading(false);
  };

  const RenderLoading = () => {
    if (isLoading) {
      return <LoadingOutlined />;
    }

    return <></>;
  };

  const handleCloseEdit = () => {
    form.resetFields();

    setIsEdit(false);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Escape") {
      handleCloseEdit();
    }
    if (inputProps?.onKeyDown) {
      inputProps.onKeyDown(event);
    }
  };

  const handleOnChange = () => {
    setIsError(false);
  };

  return (
    <div className="input-inline-api">
      {!isEdit && (
        <div className="d-flex text-input-value" onClick={handleSetEditMode}>
          <label title={tempValue}>{tempValue}</label>
          <EditOutlined />
        </div>
      )}
      {!isEdit && !tempValue && <EditOutlined onClick={handleSetEditMode} />}
      {isEdit && (
        <Form
          form={form}
          name={`form-inline-${name}`}
          className={isError ? "inline-has-error" : ""}
        >
          <Form.Item
            className="m-0"
            initialValue={tempValue}
            name={`${name}`}
            rules={[{ required: true }]}
            messageVariables={{
              label,
            }}
          >
            <Input
              {...inputProps}
              ref={inputRef}
              onBlur={handleInputBlur}
              onChange={handleOnChange}
              suffix={!hiddenLoadingIcon && <RenderLoading />}
              disabled={isLoading}
              maxLength={60}
              onKeyDown={handleKeyDown}
            />
          </Form.Item>
          <Space className="b-actions btn-action-inline" wrap size={"small"}>
            <Button
              type="primary"
              icon={<CheckOutlined style={{ fontSize: "0.8rem" }} />}
              size={"small"}
              style={{ height: 35, width: 35 }}
              onClick={handleInputBlur}
              disabled={isLoading}
            />
            <Button
              icon={<CloseOutlined style={{ fontSize: "0.8rem" }} />}
              size={"small"}
              style={{ height: 35, width: 35 }}
              onMouseDown={handleCloseEdit}
              disabled={isLoading}
            />
          </Space>
        </Form>
      )}
    </div>
  );
};

export default InputInlineApi;
