import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";
import { ArrowDownUp } from "react-bootstrap-icons";

interface DefaultProps {
  value?: number;
}
const NumberFormat = (props: DefaultProps) => {
  const { t } = useTranslation();
  const { value } = props;
  if (value === null || value === undefined) return <>--</>;

  return (
    <>
      {new Intl.NumberFormat("de-DE", {
        style: "decimal",
        maximumFractionDigits: 5
      }).format(value)}
    </>
  );
};

export default NumberFormat;
