import { Card, Modal, UploadProps } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { HTMLAttributes, useEffect, useState } from "react";
import "./dragDropZone.module.scss";
import { CuttingType } from "app/models/CuttingType";
import { useSelector } from "react-redux";
import { configSelector } from "app/redux/slides/config.slide";
import { ShopType } from "app/models/ShopType";
import { batchUploadFiles } from "app/utils";
import MillingSVG from "assets/images/MillingSVG";
import SheetMetalSVG from "assets/images/SheetMetalSVG";
import { useTranslation } from "react-i18next";

interface DefaultProps extends UploadProps<any> {
  onSelectedFiles?: any;
  onCuttingTypeChanged?: any;
}
var timeoutHandle: any;
function DragDropZone(props: DefaultProps) {
  const { onSelectedFiles, onCuttingTypeChanged, children, ...draggerProps } =
    props;
  const { t } = useTranslation();
  const config = useSelector(configSelector);
  const [dragging, setdragging] = useState(false);
  const [isOpenCuttingType, setIsOpenCuttingType] = useState(false);
  const [fileList, setFileList] = useState<any[]>();

  useEffect(() => {
    if (config.shopType != ShopType.BOTH && fileList && fileList.length && onSelectedFiles) {
      console.log(fileList);
      onSelectedFiles({ files: fileList })
    };
  }, [fileList]); 
  const uploadProps: UploadProps = {
    className: "drag-drop-area",
    beforeUpload: (file, fileList) => {
      const rs = batchUploadFiles(fileList);
      setFileList(rs.data);
      if (config.shopType == ShopType.BOTH) {
        setIsOpenCuttingType(true);
        return false;
      };
      return false;
    },
    name: "file",
    multiple: true,
    fileList: [],
    accept: `.stp, .step, .jt, .pvz, .edz, .prt, .asm, .sldasm, .sldprt, .model, .catpart, .catproduct, .3ds, .prj, .par, .asm, .psm, .sldasm, .prt, .pdf, ${
      (config.shopType == ShopType.BOTH || config.shopType === ShopType.SHEET_METAL) ? " .dxf, .dwg, .geo, .x_t, .ipt, .sab," : ""
    } ${config.shopType == ShopType.BOTH || config.shopType === ShopType.MILLING_AND_TURNING ? " .ipt," : ""}`,
  };

  const stopDrag = () => {
    setdragging(false);
  };

  const onDragging = () => {
    // if(config.shopType == ShopType.BOTH) return;
    setdragging(true);
    clearTimeout(timeoutHandle);
    timeoutHandle = window.setTimeout(stopDrag, 500);
  };

  const onCuttingTypeSelected = (cuttingType: any) => {
    setIsOpenCuttingType(false);
    if (config.shopType == ShopType.BOTH && fileList && fileList.length > 0 && onCuttingTypeChanged && onSelectedFiles) {
      onCuttingTypeChanged(cuttingType);
      onSelectedFiles({ cuttingType, files: fileList });
    };
  }
  return (
    <div className={`drop-drag-zone ${dragging && "dragging"}`} onDragOver={onDragging} onDrop={stopDrag}>
      <Dragger {...draggerProps} {...uploadProps} openFileDialogOnClick={false}>
      </Dragger>
      <div className="children-container">{children}</div>
      <div className={`drag-layer`}>
        <h3>{t("dragNDropFiles")}</h3>
      </div>
      <Modal
        width={"auto"}
        open={isOpenCuttingType}
        onCancel={setIsOpenCuttingType.bind(null, false)}
        footer={null}
        centered
        destroyOnClose
      >
        {config.shopType === ShopType.BOTH && (
          <>
            <div className="d-flex gap-4 justify-content-center card-no-border">
              <Card
                className={`cal-option`}
                onClick={onCuttingTypeSelected.bind(
                  null,
                  CuttingType.MILLING_AND_TURNING
                )}
              >
                <MillingSVG />
                <p className="sub-l mt-3">
                  {t("cuttingType.MILLING_AND_TURNING")}
                </p>
              </Card>
              <Card
                className={`cal-option`}
                onClick={onCuttingTypeSelected.bind(
                  null,
                  CuttingType.SHEET_METAL
                )}
              >
                <SheetMetalSVG />
                <p className="sub-l mt-3">{t("cuttingType.SHEET_METAL_DXF")}</p>
              </Card>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}

export default DragDropZone;
