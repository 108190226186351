export const customFilterSurfacetreatment = (inputValue: any, option: any) => {
  if (
    option?.children &&
    option?.children.toLowerCase()?.includes(inputValue.toLowerCase())
  ) {
    return true;
  } else if (inputValue === "all") {
    return true;
  }
  return false;
};

export const buildSurfaceTreatmentOption = (
  surfaceTreatments: any[],
  lang: string
) => {
  let items: any[] = [];
  surfaceTreatments.map((item: any) => {
    const localization = item?.localizations?.find(
      (l: any) => l.locale.toLowerCase() === lang
    );
    items[item.id] = localization?.name || item.method;
  });

  return items;
};

export const resetFormErrors = (form: any) => {
  const formValues = form.getFieldsValue();
  const fieldNames = Object.keys(formValues);

  fieldNames.forEach((name: string) => {
      form.setFields([
          {
              name: name,
              errors: [],
          },
      ]);
  })
}

export const setFormErrors = (form: any, errors: any) => {
  errors.forEach((validationError: any) => {
      form.setFields([
          {
              name: validationError.path,
              errors: [validationError.message],
          },
      ]);
  });
} 