import { HTMLAttributes, useState } from "react";
import { useTranslation } from "react-i18next";
import "./projectStatusTag.module.scss";
import { useSelector } from "react-redux";
import { configSelector } from "app/redux/slides/config.slide";
import "./projectPaymentStatusTag.module.scss";
import { Project } from "app/models/Project";
import { authSelector } from "app/redux/slides/auth.slide";
import { UserRole } from "app/models/UserRole";
import ProjectListManualPaymentFormModal from "../Payment/ProjectListManualPaymentFormModal";
import ProjectListStripePaymentFormModal from "../Payment/Stripe/ProjectListStripePaymentFormModal";
import { Tag } from "antd";

interface DefaultProps extends HTMLAttributes<any> {
  project: Project;
  onCompletePaymentComplete?: any;
}

function ProjectPaymentStatusTag(props: DefaultProps) {
  const { t } = useTranslation();
  const { project, onCompletePaymentComplete, ...tagProps } = props;
  const config = useSelector(configSelector);
  const [paid, setPaid] = useState(project?.order?.paid);
  const auth = useSelector(authSelector);

  const handleOnComplete = () => {
    if (onCompletePaymentComplete) {
      onCompletePaymentComplete();
    }
    setPaid(true);
  };

  if (!config.paymentEnabled) {
    return <></>;
  }

  const RenderPaymentButton = () => {
    if (auth?.user?.role == UserRole.SELLER) {
      return (
        <Tag className={`project-status`} color="warning">{t("payment.waitingForPayment")}</Tag>
      );
    }

    return (
      <ProjectListStripePaymentFormModal
        project={project}
        onComplete={handleOnComplete}
      />
    );
  };

  return (
    <>
      {paid === false && (
        <div style={{ margin: "0 auto" }}>
          <RenderPaymentButton />
        </div>
      )}
      {paid == true && <div className="list-paid-stamp"></div>}
    </>
  );
}
export default ProjectPaymentStatusTag;
