import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Modal,
  Table,
  notification,
} from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { Compliance } from "app/models/Compliance";
import complianceServices from "app/services/compliance.service";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TableData, TableFilter } from "../../models/Table";
import { set } from "lodash";

interface TableParams extends SorterResult<Compliance> {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const CompliancesPage: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const [data, setData] = useState<Compliance[]>([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [compliance, setCompliance] = useState<Compliance>();
  const [complianceDeleteId, setComplianceDeleteId] = useState<any>(undefined);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [form] = Form.useForm();
  const [submitting , setSubmitting] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const tableFilters: TableFilter = {
        sortDir: tableParams.order != "descend" ? "ASC" : "DESC",
        sortProperty: tableParams.field?.toString(),
        page: (tableParams.pagination?.current || 1) - 1,
        size: tableParams.pagination?.pageSize,
      };
      const rs: TableData = await complianceServices.list(tableFilters);
      setData(rs.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: rs.totalItems,
        },
      });
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [JSON.stringify(tableParams)]);

  useEffect(() => {
    if (compliance) {
      form.setFieldsValue(compliance);
    } else {
      form.resetFields();
    }
  }, [compliance]);
  useEffect(() => {
    setIsOpenDeleteModal(!!complianceDeleteId);
  }, [complianceDeleteId]);

  const handleTableChange: any = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<Compliance>
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const getCompliance = async (id: number) => {
    try {
      const rs = await complianceServices.detail(id);
      setCompliance(rs);
    } catch (error) {}
  };

  const showModal = (id?: number) => {
    setIsModalOpen(true);
    if (id) {
      getCompliance(id);
    }
  };

  const onComplianceSubmitted = async (values: Compliance) => {
    try {
      setSubmitting(true);
      if (values.id) {
        await complianceServices.update(values);
        let index = data?.findIndex((m) => m.id == values.id);
        data[index] = { ...data[index], ...values };
        setData([...data]);
      } else {
        const rs = await complianceServices.create(values);
        const newData = [rs, ...data];
        setData(newData);
      }
      form.resetFields();
      handleCancel();
      api.success({
        message: `${t("updateSuccess")}!`,
        description: <></>,
        placement: "topRight",
      });
      setSubmitting(false);
    } catch (error: any) {
      if (error?.exception.includes("DatabaseConstraintViolationException")) {
        form.setFields([
          {
            name: 'name',
            errors: [t("error.duplicated", {name: t("compliance")})],
          },
        ]);
        setSubmitting(false);
        return
      }
      api.error({
        message: t("updateFailed"),
        description: t("error.default"),
        placement: "topRight",
      });
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    setCompliance(undefined);
    setIsModalOpen(false);
  };

  const handleDelete = async (projectId: any) => {
    setComplianceDeleteId(projectId);
  };

  const columns: ColumnsType<Compliance> = [
    {
      title: "Id",
      dataIndex: "id",
      width: "50px",
      sorter: true,
    },
    {
      title: t("name"),
      dataIndex: "name",
      sorter: true,
    },
    {
      title: t("action"),
      key: "operation",
      fixed: "right",
      width: 200,
      render: (_: any, record: Compliance) => {
        const actionBtn = {
          label: t("edit"),
          key: "unarchive",
          icon: <EditOutlined />,
          onClick: () => showModal.bind(null, record.id),
        };

        const columnActionBtn = [
          {
            label: t("delete"),
            key: "delete",
            icon: <DeleteOutlined style={{ fontWeight: 800, fontSize: 17 }} />,
            onClick: () => handleDelete(record.id),
          },
        ];

        return (
          <Dropdown.Button
            icon={<MoreOutlined />}
            menu={{ items: columnActionBtn }}
            placement="bottomRight"
            onClick={actionBtn.onClick()}
          >
            {actionBtn.icon} {actionBtn.label}
          </Dropdown.Button>
        );
      },
    },
  ];

  const performDeleteAction = async (id: any) => {
    try {
      setLoading(true);
      const rs: Compliance = await complianceServices.deleteCompliance(id);
      api.success({
        message: t("deleted"),
        description: t("deletedNotification"),
        placement: "topRight",
      });
      setComplianceDeleteId(undefined);
      getData();
    } catch (error: any) {
      let msg = t("deletedFailed");
      api.error({
        message: `Error!`,
        description: msg,
        placement: "topRight",
      });
    }
    setLoading(false);
    setIsOpenDeleteModal(false);
    setComplianceDeleteId(undefined);
  };

  return (
    <>
      {contextHolder}
      <h3>{t("menu.compliances")}</h3>
      <div className="d-flex gap-3 mb-3 w-100 app-form">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={showModal.bind(null, 0)}
        >
          {t("compliance.add")}
        </Button>
      </div>
      <Table
        columns={columns}
        showSorterTooltip={false}
        rowKey={(record) => `surface-${record.id}`}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      <Modal
        title={t("menu.compliances")}
        open={isModalOpen}
        onOk={form.submit}
        onCancel={handleCancel}
        destroyOnClose
        okButtonProps={{
          loading: submitting,
        }}
        cancelButtonProps={{
          disabled: submitting,
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="basic"
          autoComplete="off"
          onFinish={onComplianceSubmitted}
          initialValues={{
            active: true,
          }}
          disabled={submitting}
        >
          <div className="row mt-3">
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <div className="col">
              <Form.Item
                label={t("name")}
                name="name"
                rules={[
                  {
                    required: true,
                  },
                  {
                    max: 200,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
      <Modal
        open={isOpenDeleteModal}
        title={t("popup.deleteCompliance.title")}
        onCancel={setComplianceDeleteId.bind(null, undefined)}
        onOk={performDeleteAction.bind(null, complianceDeleteId)}
        okButtonProps={{
          loading,
        }}
        cancelButtonProps={{
          disabled: loading,
        }}
      >
        <p>{t("popup.deleteCompliance.message")}</p>
      </Modal>
    </>
  );
};

export default CompliancesPage;
