import { CurrencyExchange } from "app/models/User";
import services from ".";
import { API } from "../constants/api.constant";
import { QuickSetting } from "../models/QuickSetting";

export const detail = async (id: number = 1) => {
  try {
    const rs = await services.get(
      API.QUICK_SETTINGS_BY_ID.replace(":id", id.toString())
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const update = async (data: QuickSetting) => {
  try {
    const rs = await services.put(
      API.QUICK_SETTINGS,
      data
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const getCurrencies = async () => {
  try {
    const rs = await services.get(API.CURRENCY_EXCHANGE);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const updateCurrencyExchangeRate = async (currencyExchange: CurrencyExchange) => {
  try {
    const rs = await services.put(API.CURRENCY_EXCHANGE_UPDATE, currencyExchange);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const quickSettingsServices = {
  detail,
  update,
  getCurrencies,
  updateCurrencyExchangeRate
};

export default quickSettingsServices;
