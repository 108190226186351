import { FileTextOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { EmissionReport } from "app/models/EmissionReport";
import { Part } from "app/models/Part";
import { UserRole } from "app/models/UserRole";
import { authSelector } from "app/redux/slides/auth.slide";
import partServices from "app/services/part.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NumberFormat from "../Format/NumberFormat";

interface DefaultProps {
  part?: Part;
}
function EmissionReportView(props: DefaultProps) {
  const { part } = props;
  const { t } = useTranslation();
  const auth = useSelector(authSelector);
  const [isOpenEmissionReport, setIsOpenEmissionReport] = useState(false);
  const [emissionReport, setEmissionReport] = useState<EmissionReport>();
  const getEmissionReport = async () => {
    try {
      const rs = await partServices.getEmissionReport(part?.id);
      setEmissionReport(rs);
    } catch (error) {}
  };
  useEffect(() => {
    if (
      isOpenEmissionReport &&
      part &&
      part.auto &&
      part.unitPrice &&
      auth.user?.role === UserRole.SELLER
    ) {
      getEmissionReport();
    } else {
      setEmissionReport(undefined);
    }
  }, [isOpenEmissionReport]);

  return (
    <>
      <Button
        className="ms-md-3"
        onClick={setIsOpenEmissionReport.bind(null, true)}
      >
        <FileTextOutlined /> {t("part.emissionReport")}
      </Button>
      <Modal
        open={isOpenEmissionReport}
        title={t("part.emissionReport")}
        onCancel={setIsOpenEmissionReport.bind(null, false)}
        footer={null}
        centered
        destroyOnClose
      >
        <div className="row">
          <div className="col col-9 text-label">
            {t("emissionReport.emission")}
          </div>
          <div className="col col-3">
            <NumberFormat value={emissionReport?.breakdown?.emission} /> kg
          </div>
          <div className="col col-9 text-label">
            {t("emissionReport.emissionBD.setup")}{" "}
          </div>
          <div className="col col-3">
            <NumberFormat
              value={emissionReport?.breakdown?.emissionBD?.setup}
            />{" "}
            kg
          </div>
          <div className="col col-9 text-label">
            {t("emissionReport.emissionBD.unit")}
          </div>
          <div className="col col-3">
            <NumberFormat value={emissionReport?.breakdown?.emissionBD?.unit} />{" "}
            kg
          </div>
          <div className="col col-9 text-label">
            {t("emissionReport.emissionBD.unitBD.rawMaterial")}
          </div>
          <div className="col col-3">
            <NumberFormat
              value={emissionReport?.breakdown?.emissionBD?.unitBD?.rawMaterial}
            />{" "}
            kg
          </div>
          <div className="col col-9 text-label">
            {t("emissionReport.emissionBD.unitBD.machining")}
          </div>
          <div className="col col-3">
            <NumberFormat
              value={emissionReport?.breakdown?.emissionBD?.unitBD?.machining}
            />{" "}
            kg
          </div>
          <div className="col col-9 text-label">
            {t("emissionReport.emissionBD.unitBD.additions")}
          </div>
          <div className="col col-3">
            <NumberFormat
              value={emissionReport?.breakdown?.emissionBD?.unitBD?.additions}
            />{" "}
            kg
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EmissionReportView;
