import { CurrencyExchange } from "app/models/User";
import Table, { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { useEffect, useState } from "react";
import quickSettingsServices from "app/services/quickSetting.service";
import DEIcon from "app/components/SVGs/DEIcon";
import CZIcon from "app/components/SVGs/CZIcon";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Typography,
  notification,
} from "antd";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: CurrencyExchange;
  index: number;
  children: React.ReactNode;
}

export function CurrencyExchangeTable() {
  const [currencies, setCurrencies] = useState<CurrencyExchange[]>([]);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [api, contextHolder] = notification.useNotification();

  const isEditing = (record: CurrencyExchange) =>
    `currency-${record.isoCode}` === editingKey;

  const getCurrencies = async () => {
    try {
      const rs = await quickSettingsServices.getCurrencies();
      const currencyData = rs.data.filter(
        (item: CurrencyExchange) => item.isoCode !== "EUR"
      );
      setCurrencies(currencyData);
    } catch (error) {}
  };

  useEffect(() => {
    getCurrencies();
  }, []);

  const cancel = () => {
    setEditingKey("");
  };

  const columns: ColumnsType<CurrencyExchange> = [
    {
      title: t("isoCode"),
      dataIndex: "isoCode",
    },
    {
      title: t("symbol"),
      dataIndex: "symbol",
    },
    {
      title: t("exchangeRate"),
      dataIndex: "exchangeRate",
      render: (data, record) => data,
    },
    {
      title: t("action"),
      key: "operation",
      dataIndex: "operation",
      render: (_: any, record: CurrencyExchange) => {
        if (!record.isoCode) return null;
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="text"
              icon={<CheckOutlined />}
              onClick={save.bind(null, record.isoCode)}
            ></Button>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={cancel}
            ></Button>
          </span>
        ) : (
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={edit.bind(null, record)}
            disabled={editingKey !== ""}
          ></Button>
        );
      },
    },
  ];

  const edit = (record: Partial<CurrencyExchange>) => {
    form.setFieldsValue(record);
    setEditingKey(`currency-${record.isoCode}`);
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as CurrencyExchange;

      const newData = [...currencies];
      const index = newData.findIndex((item) => key === item.isoCode);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        await quickSettingsServices.updateCurrencyExchangeRate({
          ...item,
          ...row,
        });
        setCurrencies(newData);
        setEditingKey("");
      }
      api.success({
        message: "Success!",
        description: t("exchangeRate") + "!",
        duration: 5,
        placement: "topRight",
      });
    } catch (errInfo) {
      api.error({
        message: "Failed!",
        description: t("exchangeRate") + "!",
        duration: 20,
        placement: "topRight",
      });
    }
  };

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = (
      <InputNumber type="number" size="large" style={{ width: 150 }} />
    );

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const mergedColumns = columns.map((col: any) => {
    if (col.dataIndex !== "exchangeRate") {
      return col;
    }
    return {
      ...col,
      onCell: (record: CurrencyExchange) => ({
        record,
        inputType: "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      {contextHolder}
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          pagination={false}
          dataSource={currencies}
          columns={mergedColumns}
          rowClassName="editable-row"
          rowKey={(record) => `currency-${record.isoCode}`}
        />
      </Form>
    </>
  );
}
