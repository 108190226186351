import {
  Button,
  Checkbox,
  Divider,
  Form,
  InputNumber,
  Select,
  notification,
} from "antd";
import { MachineInput } from "app/models/Machine";
import { loadingActions } from "app/redux/slides/loading.slide";
import machineSettingServices from "app/services/machineSetting.service";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
const { Option } = Select;

interface DefaultProps {
  formInputValues: any;
  formInputs: MachineInput[];
  formName: string;
  apiURL: string;
}

const SheetMetalMachineForm = (props: DefaultProps) => {
  const dispatch = useDispatch();
  const { formName, formInputValues, apiURL, formInputs } = props;
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);

  const onSubmitted = async (values: any) => {
    setSubmitting(true);
    try {
      try {
        dispatch(loadingActions.show(true));
        const rs = await machineSettingServices.updateSheetMetalMachineSettings(
          apiURL,
          values
        );
        api.success({
          message: `${t("updateSuccess")}!`,
          placement: "topRight",
        });
      } catch (error) {
        api.error({
          message: `Update failed!`,
          description: t("error.default"),
          placement: "topRight",
        });
      }
    } catch (error) {}
    dispatch(loadingActions.show(false));
    setSubmitting(false);
  };

  const renderNumberInput = (item: MachineInput) => {
    const options: any = {};
    if (item?.max && item?.max > 0) {
      options.max = item.max;
    }

    if (item?.min) {
      options.min = item.min;
    }
    
    return <InputNumber addonAfter={item.unit} {...options} />;
  };

  if (!formInputValues) {
    return <></>
  }

  return (
    <>
      {contextHolder}
      <Form
        className="mt-3"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 6 }}
        layout="horizontal"
        onFinish={onSubmitted}
        disabled={submitting}
      >
        {formInputs.map((item: MachineInput) => {
          switch (item.type) {
            case "number":
              return (
                <Form.Item
                  key={`${formName}-${item.name}`}
                  label={t(`machineSettings.${item.name}`)}
                  name={item.name}
                  tooltip={
                    t(`machineSettings.${item.name}.desc`) !=
                      `machineSettings.${item.name}.desc` && (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t(`machineSettings.${item.name}.desc`),
                          }}
                        ></div>
                      </>
                    )
                  }
                  rules={[
                    {
                      required: item.required == true ? true : false,
                    },
                  ]}
                  initialValue={formInputValues[item.name]}
                >
                  {renderNumberInput(item)}
                </Form.Item>
              );
            case "checkbox":
              return (
                <Form.Item
                  key={`${formName}-${item.name}`}
                  label={t(`machineSettings.${item.name}`)}
                  name={item.name}
                  tooltip={
                    t(`machineSettings.${item.name}.desc`) !=
                      `machineSettings.${item.name}.desc` && (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t(`machineSettings.${item.name}.desc`),
                          }}
                        ></div>
                      </>
                    )
                  }
                  rules={[
                    {
                      required: item.required == true ? true : false,
                    },
                  ]}
                  valuePropName={formInputValues[item.name] == 1 ? "checked" : ""}
                  initialValue={formInputValues[item.name] == 1}
                >
                  <Checkbox></Checkbox>
                </Form.Item>
              );
            case "multiselect":
              return (
                <Form.Item
                  key={`${formName}-${item.name}`}
                  label={t(`machineSettings.${item.name}`)}
                  name={item.name}
                  tooltip={
                    t(`machineSettings.${item.name}.desc`) !=
                      `machineSettings.${item.name}.desc` && (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t(`machineSettings.${item.name}.desc`),
                          }}
                        ></div>
                      </>
                    )
                  }
                  rules={[
                    {
                      required: item.required == true ? true : false,
                    },
                  ]}
                  initialValue={item.jsonValueKey && formInputValues[item.jsonValueKey] && formInputValues[item.jsonValueKey]}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={t("pleaseSelect")}
                    optionFilterProp="children"
                    filterOption={(
                      input: any,
                      option: any
                    ) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(
                          input.trim().toLowerCase()
                        ) >= 0
                    }
                  >
                    {item.jsonOptions &&
                      JSON.parse(item.jsonOptions).map((option: any) => {
                        return (
                          <Option
                            key={`${option.name}-${option.id}`}
                            value={option.id}
                          >
                            {option.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              );

            case "divider":
              return (
                <Divider orientation="left" key={`${formName}-${item.name}`}>
                  {t(`machineSettings.${item.name}`)}
                </Divider>
              );
          }
        })}

        <Form.Item wrapperCol={{ offset: 6, span: 6 }}>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {t("saveChanges")}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default SheetMetalMachineForm;
