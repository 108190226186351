import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationBaseDE from "./de/translation.json";
import translationBaseEN from "./en/translation.json";
import translationBaseIT from "./it/translation.json";
import translationBaseCS from "./cs/translation.json";

import translationElatxDE from "./brands/elastx/de/translation.json";
import translationElatxEN from "./brands/elastx/en/translation.json";
import translationElatxIT from "./brands/elastx/it/translation.json";
import translationElatxCS from "./brands/elastx/cs/translation.json";

import translationGmElatxDE from "./brands/gm-elastx/de/translation.json";
import translationGmElatxEN from "./brands/gm-elastx/en/translation.json";
import translationGmElatxIT from "./brands/gm-elastx/it/translation.json";
import translationGmElatxCS from "./brands/gm-elastx/cs/translation.json";

import translationPlatxDE from "./brands/plastx/de/translation.json";
import translationPlatxEN from "./brands/plastx/en/translation.json";
import translationPlatxIT from "./brands/plastx/it/translation.json";
import translationPlatxCS from "./brands/plastx/cs/translation.json";

let translationEN = translationBaseEN;
let translationDE = translationBaseDE;
let translationIT = translationBaseIT;
let translationCS = translationBaseCS;

if (process.env.REACT_APP_TRANSLATION_PATH == 'elastx') {
  translationEN = { ...translationBaseEN, ...translationElatxEN };
  translationDE = { ...translationBaseDE, ...translationElatxDE };
  translationIT = { ...translationBaseIT, ...translationElatxIT };
  translationCS = { ...translationBaseCS, ...translationElatxCS };
}

if (process.env.REACT_APP_TRANSLATION_PATH == 'plastx') {
  translationEN = { ...translationBaseEN, ...translationPlatxEN };
  translationDE = { ...translationBaseDE, ...translationPlatxDE };
  translationIT = { ...translationBaseIT, ...translationPlatxIT };
  translationCS = { ...translationBaseCS, ...translationPlatxCS };
}

if (process.env.REACT_APP_TRANSLATION_PATH == 'gm-elastx') {
  translationEN = { ...translationBaseEN, ...translationGmElatxEN };
  translationDE = { ...translationBaseDE, ...translationGmElatxDE };
  translationIT = { ...translationBaseIT, ...translationGmElatxIT };
  translationCS = { ...translationBaseCS, ...translationGmElatxCS };
}

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  it: {
    translation: translationIT,
  },
  cs: {
    translation: translationCS,
  },
};

i18n
  // .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    partialBundledLanguages: true,
    fallbackLng: "en",
    debug: process.env.REACT_APP_ENV === "development",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
