import { TableFilter } from "app/models/Table";
import { Thickness } from "app/models/Thickness";
import services from ".";
import { API } from "../constants/api.constant";

export const list = async (params?: TableFilter) => {
  try {
    const rs = await services.get(API.THICKNESS, {
      params
    });
    return rs.data;
  } catch (error) {
    throw error;
  }
};
export const create = async (data: Thickness) => {
  try {
    const rs = await services.post(API.THICKNESS, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};
export const update = async (data: Thickness) => {
  try {
    const rs = await services.put(API.THICKNESS, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};
export const deleteThickness = async (id: any) => {
  try {
    const rs = await services.delete(API.THICKNESS_BY_ID.replace(":id", id));
    return rs.data;
  } catch (error) {
    throw error;
  }
};
export const findByMaterialId = async (materialId: any) => {
  try {
    const rs = await services.get(
      API.THICKNESS_FIND_BY_MATERIAL_ID.replace(":id", materialId)
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const findAllValues = async () => {
  try {
    const rs = await services.get(
      API.THICKNESS_FIND_ALL_VALUES
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const thicknessServices = {
  list,
  create,
  update,
  deleteThickness,
  findByMaterialId,
  findAllValues
};

export default thicknessServices;
