import { CurrencyExchange } from "app/models/User";
import { projectSelector } from "app/redux/slides/project.slide";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function useProjectCurrencyExchange() {
  const project = useSelector(projectSelector).data;
  const [currencyInfo, setCurrencyInfo] = useState<CurrencyExchange>({
    exchangeRate: project?.order?.price?.currencyExchangeRate ?? 1,
    symbol: project?.order?.price?.currencySymbol,
    isoCode: project?.order?.price?.currencyIsoCode,
  });
  useEffect(() => {
    if (project) {
      setCurrencyInfo({
        exchangeRate: project.order?.price?.currencyExchangeRate ?? 1,
        symbol: project.order?.price?.currencySymbol,
        isoCode: project.order?.price?.currencyIsoCode,
      });
    }
  }, [project]);
  return currencyInfo;
}
