import { HTMLAttributes } from "react";

interface DefaultProps extends HTMLAttributes<any> {
  value: any;
}
function MaterialNumber(props: DefaultProps) {
  const { value = "" } = props;
  return <>{value.replaceAll(/[A-Za-z]/g, "").trim()}</>;
}
export default MaterialNumber;
