import {
  Button,
  Card,
  Descriptions,
  Form,
  InputNumber,
  Modal,
  Select,
  Space,
  Switch,
  notification,
} from "antd";
import type { TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import CurrencyFormat from "app/components/Format/CurrencyFormat";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ListProjects from "../../components/Project/ListProjects";
import { ProjectStatus } from "../../models/Project";
import { SurfaceTreatment } from "../../models/SurfaceTreatment";
import { User } from "../../models/User";
import customerServices from "../../services/customer.service";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { Option } = Select;

interface DataType {
  name: {
    first: string;
    last: string;
  };
  gender: string;
  email: string;
  login: {
    uuid: string;
  };
}

interface TableParams extends SorterResult<SurfaceTreatment> {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const CustomerOverviewPage: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const { id } = useParams();
  const [customer, setCustomer] = useState<User>();
  const [form] = Form.useForm();
  const [customerSubmiting, setCustomerSubmiting] = useState(false);
  const [modal, modalContextHolder] = Modal.useModal();

  const getCustomer = async () => {
    try {
      const rs = await customerServices.detail(Number(id));
      setCustomer(rs);
      form.setFieldsValue(rs);
    } catch (error) {}
  };

  useEffect(() => {
    getCustomer();
  }, []);

  const updateCustomerSubmit = async (values: any) => {
    setCustomerSubmiting(true);
    try {
      const data = {
        id: customer?.id,
        discount: values.discount,
        calculationEnabled: values.calculationEnabled,
      };
      await customerServices.update(data);
      api.info({
        duration: 0,
        message: `${t("success")}!`,
        placement: "topRight",
      });
    } catch (error) {
      api.error({
        duration: 0,
        message: `Failed!`,
        placement: "topRight",
      });
    }
    setCustomerSubmiting(false);
  };

  const confirmEnableUser = () => {
    modal.confirm({
      title: t("confirm"),
      icon: <ExclamationCircleOutlined />,
      content: t("customer.confirmVerificateUser.content"),
      okText: t("ok"),
      cancelText: t("cancel"),
      onOk: async () => {
        try {
          await customerServices.activeUser(Number(id));
          api.info({
            duration: 0,
            message: `${t("success")}!`,
            placement: "topRight",
          });
          getCustomer();
        } catch (error) {
          api.error({
            duration: 0,
            message: `Failed!`,
            placement: "topRight",
          });
        }
      },
    });
  };

  return (
    <>
      {contextHolder}
      {modalContextHolder}
      <h3>{t("customer.detail.heading")}</h3>
      <Descriptions title={t("contact")}>
        <Descriptions.Item label={t("fullName")}>
          {customer?.name}
        </Descriptions.Item>
        <Descriptions.Item label={t("email")}>
          {customer?.email}
        </Descriptions.Item>
        <Descriptions.Item label={t("phone")}>
          {customer?.phone}
        </Descriptions.Item>
        <Descriptions.Item label={t("companyName")}>
          {customer?.companyName}
        </Descriptions.Item>
      </Descriptions>
      <hr />
      <div className="row">
        <div className="col col-12 col-md-6">
          <p className="sub-l">{t("billingAddress")}</p>
          <div>
            <p>
              {t("contactPerson")}: {customer?.billingAddress?.firstName}{" "}
              {customer?.billingAddress?.lastName}
            </p>
            <p>
              {customer?.billingAddress?.streetName}{" "}
              {customer?.billingAddress?.houseNumber}
            </p>
            <p>
              {customer?.billingAddress?.postCode}{" "}
              {customer?.billingAddress?.city}
            </p>
            <p>
              {customer?.billingAddress?.state}{" "}
              {customer?.billingAddress?.country}
            </p>
          </div>
        </div>
        <div className="col col-12 col-md-6">
          <p className="sub-l">{t("shippingAddress")}</p>
          <div>
            <p>
              {t("contactPerson")}: {customer?.shippingAddress?.firstName}{" "}
              {customer?.shippingAddress?.lastName}
            </p>
            <p>
              {customer?.shippingAddress?.streetName}{" "}
              {customer?.shippingAddress?.houseNumber}
            </p>
            <p>
              {customer?.shippingAddress?.postCode}{" "}
              {customer?.shippingAddress?.city}
            </p>
            <p>
              {customer?.shippingAddress?.state}{" "}
              {customer?.shippingAddress?.country}
            </p>
          </div>
        </div>
      </div>
      <hr />

      <div className="row">
        <div className="col col-12 col-md-6">
          <Form
            layout="vertical"
            form={form}
            labelCol={{ span: 16 }}
            autoComplete="none"
            onFinish={updateCustomerSubmit}
          >
            <Form.Item
              name="discount"
              label={t("customer.form.discount")}
              rules={[{ required: true }]}
            >
              <InputNumber addonAfter="%" min={0} />
            </Form.Item>
            <Form.Item
              className="mb-0"
              name="calculationEnabled"
              label={t("customer.form.automaticCalculation")}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Button type="primary" htmlType="submit" className="mt-3">
              {t("save")}
            </Button>
          </Form>
        </div>
        <div className="col col-12 col-md-6">
          {!customer?.enabled && (
            <Button type="primary" onClick={confirmEnableUser}>
              {t("enableLogin")}
            </Button>
          )}
        </div>
      </div>

      <hr />
      <Space size={16} direction="horizontal">
        <Card className="b-shadow">
          <p className="sub-l">{t("customer.summary.parts")}</p>
          <label>{customer?.numParts}</label>
        </Card>
        <Card className="b-shadow">
          <p className="sub-l">{t("customer.summary.order")}</p>
          <label>
            <CurrencyFormat value={customer?.orderCostSum} />
          </label>
        </Card>
        <Card className="b-shadow">
          <p className="sub-l">{t("customer.summary.calculations")}</p>
          <label>{customer?.numCalculations}</label>
        </Card>
      </Space>
      <hr />
      <h6 className="fw-bold">{t("projects")}</h6>
      <ListProjects
        createById={Number(id)}
        statuses={[
          ProjectStatus.IN_PROGRESS,
          ProjectStatus.CHECKOUTED,
          ProjectStatus.REQUESTED_OFFER,
          ProjectStatus.PRICE_ADAPTED,
          ProjectStatus.BUYER_APPROVED,
          ProjectStatus.BUYER_REJECTED,
          ProjectStatus.SELLER_APPROVED,
          ProjectStatus.SELLER_REJECTED,
        ]}
      />
    </>
  );
};

export default CustomerOverviewPage;
