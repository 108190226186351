import { ReactNode, useEffect, useState } from "react";
import LoadingComponent from "../Loading/LoadingComponent";
import { Button, Col, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import "./paymentModal.module.scss";
import { CreditCardOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { authSelector } from "app/redux/slides/auth.slide";
import { configSelector } from "app/redux/slides/config.slide";
import { UserRole } from "app/models/UserRole";
import { Project, ProjectStatus } from "app/models/Project";

interface DefaultProps {
  children?: ReactNode;
  project: Project;
  triggerShowModal?: boolean;
  hideButton?: boolean;
  onModalClose?: any;
}

const PaymentFormModal = (props: DefaultProps) => {
  const { project, triggerShowModal, hideButton, onModalClose } = props;
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const auth = useSelector(authSelector);
  const config = useSelector(configSelector);

  useEffect(() => {
    if (triggerShowModal != undefined) {
      setIsOpen(triggerShowModal);
    }
  }, [triggerShowModal]);

  const handlePayLater = () => {
    setIsOpen(false);
    if (onModalClose) {
      onModalClose();
    }
  };

  const handleOnCancel = () => {
    setIsOpen(false);
    if (onModalClose) {
      onModalClose();
    }
  };

  if (
    (auth.user && auth.user.role != UserRole.BUYER) ||
    !config?.paymentEnabled ||
    project?.order?.paid ||
    (project?.status != ProjectStatus.SELLER_APPROVED && project?.status != ProjectStatus.BUYER_APPROVED)
  ) {
    return <></>;
  }

  return (
    <div className="" style={{ position: "relative" }}>
      <LoadingComponent loading={loading} />
      {(!hideButton || hideButton == undefined) && (
        <Button type="primary" onClick={setIsOpen.bind(null, true)}>
          <CreditCardOutlined />
          {t("payment.pay")}
        </Button>
      )}
      <Modal
        open={isOpen}
        title={t("payment")}
        width={"65vw"}
        className="modal-body-scroll payment-modal"
        onCancel={handleOnCancel}
        destroyOnClose
        footer={null}
        bodyStyle={{ overflow: "auto" }}
        centered
      >
        <div>
          <div
            className="d-flex flex-column align-items-center"
            style={{ minHeight: 750 }}
          >
            {props.children}
          </div>
          <div
            className="d-flex flex-column align-items-center"
            style={{ fontSize: 13 }}
          >
            <a className="ant-link" onClick={handlePayLater}>
              {t("payment.payLater")}
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PaymentFormModal;
