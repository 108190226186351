import { Project } from "app/models/Project";
import Stripe from "./Stripe/Stripe";
import { useSelector } from "react-redux";
import { authSelector } from "app/redux/slides/auth.slide";
import { configSelector } from "app/redux/slides/config.slide";
import { UserRole } from "app/models/UserRole";
import { getRouterByRole } from "app/utils";

interface DefaultProps {
  project: Project;
  redirectUrl?: string;
  onCompletePayment: any;
}

const PaymentForm = (props: DefaultProps) => {
  const { project, redirectUrl, onCompletePayment } = props;
  const auth = useSelector(authSelector);
  const config = useSelector(configSelector);
  const currentDomain = window.location.hostname;
  const paymentRedirect =
    !redirectUrl || redirectUrl == ""
      ? `${currentDomain}/${getRouterByRole(auth.user?.role)}/projects/${project.id}`
      : redirectUrl;

  if (
    auth.user?.role !== UserRole.BUYER ||
    !config.paymentEnabled ||
    project?.order?.paid
  ) {
    return <></>;
  }

  return (
    <div className="col col-12">
      <Stripe
        onCompletePayment={onCompletePayment}
        project={project}
        redirectUrl={paymentRedirect}
      />
    </div>
  );
};

export default PaymentForm;
