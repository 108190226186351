import services from ".";
import { API } from "../constants/api.constant";
import { SurfaceTreatment } from "../models/SurfaceTreatment";

export const list = async () => {
  try {
    const rs = await services.get(API.MACHINE_SETTINGS);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const update = async (data: SurfaceTreatment) => {
  try {
    const rs = await services.post(API.MACHINE_SETTINGS, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const sheetMetalMachineSettings = async () => {
  try {
    const rs = await services.get(API.SHEET_METAL_MACHINE_SETTINGS);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const updateSheetMetalMachineSettings = async (api: string, data: any) => {
  try {
    const rs = await services.post(api, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const machineSettingServices = {
  list,
  update,
  sheetMetalMachineSettings,
  updateSheetMetalMachineSettings
};

export default machineSettingServices;
