import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import { Material } from "app/models/Material";
import { RawMaterial } from "app/models/RawMaterial";
import { ShopType } from "app/models/ShopType";
import { Thickness } from "app/models/Thickness";
import { configSelector } from "app/redux/slides/config.slide";
import { currencySelector } from "app/redux/slides/user.slide";
import thicknessServices from "app/services/thickness.service";
import { HTMLAttributes, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface DefaultProps extends HTMLAttributes<any> {
  material?: Material;
}
function ThicknessView(props: DefaultProps) {
  const { material } = props;
  const config = useSelector(configSelector);
  const currency = useSelector(currencySelector);
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [formAddThickness] = Form.useForm();
  const [formThickness] = Form.useForm();
  const [thicknessData, setThicknessData] = useState<Thickness[]>([]);
  const [editingKey, setEditingKey] = useState("");
  const [loading, setLoading] = useState(true);
  const isEditing = (record: RawMaterial) =>
    `thickness-${record.id}` === editingKey;

  const getThickness = async (materialId: number) => {
    setLoading(true);
    if (config.shopType === ShopType.MILLING_AND_TURNING) return;
    try {
      const rs = await thicknessServices.findByMaterialId(materialId);
      setThicknessData(rs);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    if (material) {
      getThickness(material.id);
    }
  }, [material]);

  const editThickness = (record: Thickness) => {
    formThickness.setFieldsValue(record);
    setEditingKey(`thickness-${record.id}`);
  };

  const saveThickness = async (id: number) => {
    try {
      const row = (await formThickness.validateFields()) as Thickness;
      const newData = [...thicknessData];
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        await thicknessServices.update({
          ...item,
          ...row,
        });
        setThicknessData(newData);
        setEditingKey("");
      }
      api.success({
        message: "Success!",
        description: "Thickness!",
        duration: 5,
        placement: "topRight",
      });
    } catch (error) {
      api.error({
        message: "Failed!",
        description: "Thickness!",
        duration: 20,
        placement: "topRight",
      });
    }
  };

  const deleteThickness = async (id: number) => {
    try {
      await thicknessServices.deleteThickness(id);
      const index = thicknessData.findIndex((item) => id === item.id);
      thicknessData.splice(index, 1);
      setThicknessData([...thicknessData]);
    } catch (error) {}
  };

  const cancelThickness = () => {
    setEditingKey("");
  };
  const createThickness = async (values: any) => {
    try {
      values.materialId = material?.id;
      const rs = await thicknessServices.create(values);
      setThicknessData([rs, ...thicknessData]);
      formAddThickness.resetFields();
    } catch (error) {}
  };

  const thicknessColumns: any = [
    {
      title: t("part.form.thickness"),
      dataIndex: "value",
      editable: true,
    },
    {
      title: t("material.table.pricePerKilo"),
      dataIndex: "pricePerKilo",
      editable: true,
    },
    {
      title: t("action"),
      key: "operation",
      fixed: "right",
      width: 150,
      render: (_: any, record: Thickness) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="text"
              icon={<CheckOutlined />}
              onClick={saveThickness.bind(null, record.id)}
            ></Button>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={cancelThickness}
            ></Button>
          </span>
        ) : (
          <span>
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={editThickness.bind(null, record)}
            ></Button>
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={deleteThickness.bind(null, record.id)}
            ></Button>
          </span>
        );
      },
    },
  ];
  const mergedThicknessColumns = thicknessColumns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Thickness) => ({
        record,
        inputType: "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const EditableCell: React.FC<any> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = <InputNumber type="number" />;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item name={dataIndex} style={{ margin: 0 }}>
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  if (!thicknessData) return <></>;

  return (
    <>
      {contextHolder}
      <Card className="mt-4 d-group">
        <p className="text-label group-title">{t("part.form.thickness")}</p>
        <Form
          form={formAddThickness}
          className="app-form"
          layout="vertical"
          onFinish={createThickness}
        >
          <Row gutter={16} style={{ display: "flex" }}>
            <Col
              span={10}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Form.Item
                className="mb-0"
                name="value"
                rules={[{ required: true }]}
                label={t("part.form.thickness")}
              >
                <InputNumber type="number" style={{ width: "100%" }} min={0}/>
              </Form.Item>
            </Col>
            <Col
              span={10}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Form.Item
                className="mb-0"
                name="pricePerKilo"
                label={t("material.table.pricePerKilo")}
              >
                <InputNumber type="number" style={{ width: "100%" }} min={0}  prefix={'€'}/>
              </Form.Item>
            </Col>
            <Col
              span={4}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div> {/* Empty div for alignment purposes */}</div>
              <Button type="primary" htmlType="submit">
                Add New
              </Button>
            </Col>
          </Row>
        </Form>
        {thicknessData && (
          <Form form={formThickness} component={false}>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Table
              className="mt-3"
              columns={mergedThicknessColumns}
              dataSource={thicknessData}
              pagination={false}
              rowKey={(record) => `thickness-${record.id}`}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              loading={loading}
            />
          </Form>
        )}
      </Card>
    </>
  );
}

export default ThicknessView;
