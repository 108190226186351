import { useTranslation } from "react-i18next";

export const useRuleStringRequired = (required: boolean = true) => {
  const { t } = useTranslation();
  return [
    { required: required },
    {
      validator: (_: any, value: any) =>
        !(value && !value.trim())
          ? Promise.resolve()
          : Promise.reject(new Error(t("validate.required") || "")),
    },
  ];
};

// contain at least 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character please change to contain at least 8 characters, at least 1 uppercase letter, 1 lowercase letter
// /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
export const useRulePassword = () => {
  const { t } = useTranslation();
  return [
    { required: true },
    {
      pattern:
        /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
      message: t("validate.passwordRegexMedium"),
    },
  ];
};

export const useRuleMaterialNumber = () => {
  const { t } = useTranslation();
  return [
    { required: true },
    {
      pattern:
        /^\d{1,}(?:\.\d{1,}(?:\s\w{1,}|\w{1,})?)?$/g,
      message: t("validate.notValid"),
    },
  ];
};