import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import paymentServices from "app/services/payment.service";
import { Project } from "app/models/Project";
const key = process.env.REACT_APP_STRIPE_PUBLIC_KEY
  ? process.env.REACT_APP_STRIPE_PUBLIC_KEY
  : "";

interface DefaultProps {
  project: Project;
  redirectUrl: string;
  onCompletePayment: any
}

const Stripe = (props: DefaultProps) => {
  const { project, redirectUrl, onCompletePayment } = props;
  const [clientSecret, setClientSecret] = useState("");
  const stripePromise = loadStripe(key);

  useEffect(() => {
    createCheckOutSession();
  }, []);

  const createCheckOutSession = async () => {
    const rs: any = await paymentServices.createCheckOutSession(
      project.id,
      redirectUrl
    );
    setClientSecret(rs.clientSecret);
  };

  const onComplete = () => {
    onCompletePayment();
  }

  return (
    <>
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret, onComplete }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </>
  );
};

export default Stripe;
