/* eslint-disable no-new-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  SettingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Spin,
  Tag,
  Upload,
  UploadFile,
  UploadProps,
  notification,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { RcFile } from "antd/lib/upload/interface";
import { DeburringType } from "app/models/DeburringType";
import { LaserGas } from "app/models/LaserGas";
import { LayerType } from "app/models/LayerType";
import { CurrencyExchange } from "app/models/User";
import { configSelector } from "app/redux/slides/config.slide";
import { materialSelector } from "app/redux/slides/material.slide";
import Utils from "app/utils";
import { HTMLAttributes, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CuttingType } from "../../models/CuttingType";
import { Material } from "../../models/Material";
import { MaterialGroup } from "../../models/MaterialGroup";
import { Part, PartPreview } from "../../models/Part";
import { ActionMode } from "../../models/PartActionMode";
import { PartViewMode } from "../../models/PartViewMode";
import { Project, ProjectStatus } from "../../models/Project";
import { RollingDirection } from "../../models/RollingDirection";
import { SurfaceTreatment } from "../../models/SurfaceTreatment";
import { UserRole } from "../../models/UserRole";
import { authSelector } from "../../redux/slides/auth.slide";
import {
  projectActions,
  projectSelector,
} from "../../redux/slides/project.slide";
import partServices from "../../services/part.service";
import AlertManualReason from "../Alert/AlertManualReason";
import AlertPartWarning from "../Alert/AlertPartWarning";
import InputNumberInline from "../Form/InputNumberInline";
import CurrencyFormat from "../Format/CurrencyFormat";
import LocalizationName from "../Locale/LocalizationName";
import MaterialNumber from "../Material/MaterialNumber";
import PdfFileIcon from "../SVGs/PdfFileIcon";
import CadFileItemDeleteModal from "./CadFileItemDeleteModal";
import CadFileItemInfo from "./CadFileItemInfo";
import CostReportModal from "./CostReportModal";
import DropdownActions from "./DropdownActions";
import DxfSelectLayer from "./DxfSelectLayer";
import MaterialSelectModal from "./MaterialSelectModal";
import PriceAdaptForm from "./PriceAdaptForm";
import PriceReference from "./PriceReference";
import SurfaceTreatmentSelector from "./SurfaceTreatmentSelector";
import "./cadFileItem.module.scss";
import { ShapeTypes } from "../Shape/ShapeConstants";

const { Option } = Select;

const isDxf = (fileName?: string) => {
  return !!fileName && fileName.toLowerCase().indexOf(".dxf") > -1;
};
const isDwg = (fileName?: string) => {
  return !!fileName && fileName.split(".").pop()?.toLowerCase() === "dwg";
};
const isStp = (fileName?: string) => {
  return (
    !!fileName &&
    (fileName.toLowerCase().indexOf(".step") > -1 ||
      fileName.toLowerCase().indexOf(".stp") > -1)
  );
};

const sheetMetalType: any = [
  CuttingType.SHEET_METAL_DWG,
  CuttingType.SHEET_METAL_DXF,
  CuttingType.SHEET_METAL_STP,
];

const sheetMetalDxfDwgType: any = [
  CuttingType.SHEET_METAL_DWG,
  CuttingType.SHEET_METAL_DXF,
];

interface DefaultProps extends HTMLAttributes<any> {
  file?: UploadFile<any>;
  pdfFile?: RcFile;
  type?: CuttingType;
  part?: Part;
  mode?: PartViewMode;
  pricePreview?: number;
  pricePreviewLoading?: boolean;
  onChange?: any;
  onQuantityChange?: any;
  onPartUploadedChange?: any;
  onPartRender?: any;
  materialGroups?: MaterialGroup[];
  groups?: any;
  dataCuttingType?: any;
  onDeletedPart?: any;
}

function CadFileItemSheetMetal(props: DefaultProps) {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const config = useSelector(configSelector);
  const { t } = useTranslation();
  const { data, isCalcAll } = useSelector(projectSelector);
  const { projectId, accessCode } = useParams();
  const {
    type,
    file,
    pdfFile,
    mode = PartViewMode.EDIT,
    pricePreview,
    pricePreviewLoading,
    onChange,
    onQuantityChange,
    onPartUploadedChange,
    onPartRender,
    materialGroups,
    groups,
    dataCuttingType,
    onDeletedPart,
    ...viewProps
  } = props;
  const dataPart: any = props.part;
  const { thicknesses } = useSelector(materialSelector);
  const [cuttingType, setCuttingType] = useState(dataCuttingType);
  const [part, setPart] = useState<Part>(dataPart);
  const uploading = useRef(false);
  const adaptForm: any = useRef();
  const [project] = useState<Project>(data);
  const [uploaded, setUploaded] = useState(!!dataPart);
  const [parameterUploading, setParameterUploading] = useState(false);
  const [partForm] = Form.useForm();
  const [quantityForm] = Form.useForm();
  const [openDelete, setOpenDelete] = useState(false);
  const [file2D, setFile2D] = useState<RcFile | undefined>(pdfFile);
  const [technicalDrawingError, setTechnicalDrawingError] = useState<
    string | null
  >(null);
  const [materialGroup, setMaterialGroup] = useState(
    !!part
      ? groups?.find((x: MaterialGroup) => x.id === part?.materialGroupId) ||
          undefined
      : undefined
  );
  const [material, setMaterial] = useState<Material | undefined>(
    dataPart?.material
  );
  const [surfaceTreatments, setSurfaceTreatments] = useState<
    SurfaceTreatment[]
  >(materialGroup?.surfaceTreatments || []);
  const [surfaceTreatmentPOWDId, setSurfaceTreatmentPOWDId] = useState<
    number | null
  >(null);
  const [viewMode, setViewMode] = useState<PartViewMode>(
    mode ||
      ((!!file || (part && !part.material)) && PartViewMode.EDIT) ||
      (part &&
        !!part.material &&
        (part.totalPrice !== null || !part?.auto) &&
        (project?.selfCalculation
          ? PartViewMode.ADAPT
          : PartViewMode.UPDATED)) ||
      PartViewMode.EDIT
  );
  const [referenceCosts, setReferenceCosts] = useState<any>();
  const [error, setError] = useState<any>();
  const [deleted, setDeleted] = useState(false);
  const [uploadingTechDrawingFile, setUploadingTechDrawingFile] =
    useState(false);
  const [dataPartForm, setDataPartForm] = useState<any>(part || {});
  const [selectedLayers, setSelectedLayers] = useState<any>(
    part?.dxfLayers || {}
  );
  const [partPreview, setPartPreview] = useState<PartPreview>();
  const analyzingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [isDirectSubmit, setIsDirectSubmit] = useState(false);
  const [isSelectedLaserMarking, setIsSelectedLaserMarking] = useState(false);
  const [thickness, setThickness] = useState();
  const [isShowLaserGas, setIsShowLaserGas] = useState(false);
  const sheetMetalDxfDwgCuttingType = part ? part.cuttingType : cuttingType;
  const currencyInfo: CurrencyExchange = {
    exchangeRate: project?.order?.price?.currencyExchangeRate,
    symbol: project?.order?.price?.currencySymbol,
    isoCode: project?.order?.price?.currencyIsoCode,
  };

  const disableClickEvent = (event: any) => {
    event.stopPropagation();
  };

  const onUploadTechDrawingFile = async (file: any) => {
    setUploadingTechDrawingFile(true);
    try {
      await partServices.uploadTechDrawingFile({
        id: part.id,
        file,
      });
      setFile2D(undefined);
    } catch (error) {}
    setUploadingTechDrawingFile(false);
  };

  const upload2DProps: UploadProps = {
    beforeUpload: async (file: any, fileList) => {
      setFile2D(file);
      setTechnicalDrawingError(null);
      return false;
    },
    multiple: false,
    fileList: [],
    accept: `.pdf`,
  };

  const uploadFile = async () => {
    setError(undefined);
    if (!file) return;
    if (part || uploading.current || uploaded) return;
    uploading.current = true;
    dispatch(
      projectActions.setUploadPart({
        key: file.uid,
        data: null,
      })
    );
    try {
      if (file && (file.size || 0) > 1024 * 1000 * 20) {
        throw new Object({
          message: t("error.part.maxSize"),
          error: "MAX_SIZE",
        });
      }
      let ct: CuttingType = cuttingType;
      if (isStp(file.name)) {
        ct = CuttingType.SHEET_METAL_STP;
      } else if (isDxf(file.name)) {
        ct = CuttingType.SHEET_METAL_DXF;
      } else if (isDwg(file.name)) {
        ct = CuttingType.SHEET_METAL_DWG;
      }
      setCuttingType(ct);
      // console.log(ct, cuttingType)
      // if (part == undefined && (isStp(file.name) || isDxf(file.name))) {
      //   partServices
      //     .uploadFileToPreview({
      //       cuttingType: ct,
      //       cadFile: file,
      //     })
      //     .then((rs: PartPreview) => setPartPreview(rs));
      // }

      const rs: Part = await partServices.upload({
        projectId,
        cuttingType: ct,
        cadFile: file,
      });

      rs.surfaceTreatmentIds = [];
      setPart(rs);
      partForm.setFieldValue("partId", rs.id);
      setUploaded(true);
      setViewMode(PartViewMode.EDIT);
      dispatch(projectActions.setPart(rs));
      if (onPartUploadedChange) {
        onPartUploadedChange(rs);
      }
      dispatch(
        projectActions.setUploadPart({
          key: file.uid,
          data: rs,
        })
      );
      dispatch(projectActions.setPartStatus({ id: rs.id, data: viewMode }));
    } catch (error: any) {
      if (error && error.error !== "MAX_SIZE")
        error.message = t("project.error.calculation");
      setError(error);
      dispatch(
        projectActions.setUploadPart({
          key: file.uid,
          data: false,
        })
      );
    }
  };

  const updateParameters = async (values: any) => {
    setError(undefined);
    setParameterUploading(true);
    try {
      if (file2D) {
        await onUploadTechDrawingFile(file2D);
      }
      let rs: any = undefined;

      switch (part.cuttingType) {
        case CuttingType.SHEET_METAL_STP:
          rs = await partServices.updateSheetMetalStpParameters(values);
          break;
        case CuttingType.SHEET_METAL_DXF:
          values.dxfLayers = selectedLayers;
          rs = await partServices.updateSheetMetalDxfParameters(values);
          break;
        case CuttingType.SHEET_METAL_DWG:
          values.dxfLayers = selectedLayers;
          rs = await partServices.updateSheetMetalDwgParameters(values);
          break;
        default:
          break;
      }
      if (rs) {
        dispatch(projectActions.setPart(rs));
        if (rs.auto && part?.materialId != values.materialId) {
          getReferenceCosts();
        }
        setViewMode(
          project?.selfCalculation ? PartViewMode.ADAPT : PartViewMode.UPDATED
        );
        setPart(rs);
      }
    } catch (error: any) {
      if (error.errorCode == "INVALID_TROVALIZE") {
        partForm.setFields([
          {
            name: ["deburringType"],
            value: DeburringType.NO,
            errors: [t("partCannotBeTrovalized")],
          },
        ]);
      } else if (error) {
        error.message = t("project.error.calculation");
        if (
          error.exception ===
          "de.gocad.exception.UserCalculationDisabledException"
        ) {
          error.message = t("project.error.calculationDisabled");
        }
        setError(error);
      }
    }
    setParameterUploading(false);
  };

  const getReferenceCosts = async () => {
    if (viewMode === PartViewMode.READ_ONLY) return;
    try {
      const rs = await partServices.getReferenceCosts(part?.id);
      setReferenceCosts(rs);
    } catch (error) {}
  };

  const onSelectedLayers = (layers: any) => {
    setSelectedLayers(layers);
    setMarkLayer(layers);
    partForm.setFieldValue("dxfLayers", layers);
  };

  const onDxfLayerLoad = (layers: any) => {
    setMarkLayer(layers);
  };

  const setMarkLayer = (layers: any) => {
    if (!layers) {
      return;
    }

    for (const key in layers) {
      if (layers.hasOwnProperty(key) && layers[key] == LayerType.MARK) {
        setIsSelectedLaserMarking(true);
        break;
      }
    }
  };

  useEffect(() => {
    if (part) {
      partForm.setFieldValue("partId", part?.id);
      partForm.setFieldValue("materialGroupId", materialGroup?.id);
    }
    uploadFile();
    if (onPartRender) {
      onPartRender(cuttingType);
    }
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange(part);
    }
    if (onPartUploadedChange) {
      onPartUploadedChange(part);
    }
    if (part) {
      quantityForm.setFieldValue("quantity", part.quantity);
      if (part?.auto && part?.totalPrice > 0 && !accessCode) {
        getReferenceCosts();
      }
      if (sheetMetalDxfDwgType.includes(part?.cuttingType)) {
        // if (!part.thickness) partForm.setFieldValue("thickness", 1);
        if (!part.dxfLayers) partForm.setFieldValue("dxfLayers", {});
      }
    }
  }, [part]);

  useEffect(() => {
    if (materialGroup) {
      partForm.setFieldValue(
        "surfaceTreatmentIds",
        materialGroup.id === part?.materialGroupId
          ? part?.surfaceTreatmentIds
          : []
      );

      let surfaceTreatments: SurfaceTreatment[] = [];
      materialGroup.surfaceTreatments?.map((s: any) => {
        if (s.active) {
          surfaceTreatments.push(s);
          if (s.abbr == "POWD") {
            setSurfaceTreatmentPOWDId(s.id);
          }
        }
      });

      setSurfaceTreatments(surfaceTreatments);

      partForm.setFieldValue("materialGroupId", materialGroup.id);
      if (materialGroup.abbr == "St") {
        partForm.setFieldValue("laserGas", LaserGas.O2);
        setIsShowLaserGas(true);
      } else {
        setIsShowLaserGas(false);
      }
    }
  }, [materialGroup]);

  useEffect(() => {
    if (material) {
      partForm.setFieldValue("materialId", material?.id);
      const g = materialGroups?.find((g) => g.id === material.group?.id);
      setMaterialGroup(g);
      if (
        isCuttingTypeSheetMetalDxfDwg &&
        material.thicknesses?.findIndex(
          (t) => t.value == partForm.getFieldValue("thickness")
        ) == -1
      ) {
        if (material.thicknesses.length === 1) {
          partForm.setFieldValue("thickness", material.thicknesses[0].value);
        } else {
          partForm.setFieldValue("thickness", undefined);
        }
      }
    }
  }, [material]);

  useEffect(() => {
    if (isCalcAll && viewMode === PartViewMode.EDIT) {
      partForm.submit();
    }
  }, [isCalcAll]);

  useEffect(() => {
    if (part) {
      dispatch(projectActions.setPartStatus({ id: part.id, data: viewMode }));
    }
  }, [viewMode]);

  const onEdit = (event: any) => {
    event.stopPropagation();
    setViewMode(PartViewMode.EDIT);
    setMaterial(part?.material);
    if (part) {
      setDataPartForm(part);
      partForm.setFieldValue("numberOfThreads", part.numberOfThreads || 0);
      partForm.setFieldsValue(part);
      if (!part.dxfLayers) {
        partForm.setFieldValue("dxfLayers", {});
      }
    }
  };

  const onEditCancel = () => {
    setViewMode(
      project?.selfCalculation ? PartViewMode.ADAPT : PartViewMode.UPDATED
    );
    setFile2D(undefined);
  };

  const showDeleteModal = () => {
    setOpenDelete(true);
  };

  const onActionsSubmmitted = (mode: any) => {
    try {
      switch (mode) {
        case ActionMode.MOVE:
          onDeletedCallBack();
          api.info({
            message: `Part Move`,
            description: <>Part "{part.partFileName} moved!"</>,
            placement: "topRight",
          });
          break;
        case ActionMode.VIEW:
          break;
        default:
          break;
      }
    } catch (error) {}
  };

  const onMaterialSelected = (data: Material, thickness: any) => {
    setMaterial(data);
    setMaterialGroup(data.group);
    partForm.setFieldValue("materialId", data.id);
    if (thickness && isCuttingTypeSheetMetalDxfDwg) {
      partForm.setFieldValue("thickness", thickness);
    }
  };

  const onPartChange = (values: any) => {
    if (values.thickness) {
      setThickness(values.thickness);
    }
    if (partForm.getFieldValue("thickness") > 8) {
      partForm.setFieldValue("laserGas", LaserGas.O2);
    }
    setDataPartForm({
      ...dataPartForm,
      ...values,
    });
  };

  const onFormQuantitySubmit = async (quantity: any) => {
    try {
      const rs = await partServices.updateQuantity({
        id: part?.id,
        quantity,
      });
      setPart(rs);
      dispatch(projectActions.setPart(rs));
      if (adaptForm) {
        adaptForm.current?.updateAdapt(rs.unitPrice);
      }
      if (onQuantityChange) onQuantityChange(quantity);
    } catch (error) {}
  };

  const onDeletedCallBack = () => {
    setDeleted(true);
    if (onDeletedPart) {
      onDeletedPart(part);
    }
  };

  const onPriceAdapted = (part: any) => {
    setPart({ ...part });
    if (adaptForm) {
      adaptForm.current?.updateAdapt(part.adaptedUnitPrice);
    }
  };

  const onQuantityChanged = async (value: number, quanlities: any[]) => {
    try {
      await getReferenceCosts();
    } catch (error) {}
  };

  useEffect(() => {
    if (project?.archived == true) {
      setViewMode(PartViewMode.READ_ONLY);
    }
  }, [project?.archived]);

  const showDeleteButton =
    error ||
    (uploaded &&
      ((viewMode === PartViewMode.ADAPT && project?.selfCalculation) ||
        (viewMode !== PartViewMode.CHECKOUT &&
          auth.user?.sub === project?.createdBy?.email)));

  const showCalculationButton = uploaded && viewMode === PartViewMode.EDIT;
  const showActionButton =
    viewMode !== PartViewMode.READ_ONLY &&
    part &&
    ((showDeleteButton && !showCalculationButton) || !(part?.totalPrice > 0));

  const validatePartBeforeSubmit = async () => {
    let valid = true;
    const partSf = partForm.getFieldValue("surfaceTreatmentIds");

    const is2DFileExsit =
      part && part?.pdfFileName ? true : file2D ? true : false;

    setTechnicalDrawingError(null);

    if (partSf && partSf.includes(surfaceTreatmentPOWDId) && !is2DFileExsit) {
      setTechnicalDrawingError(t("required"));
      valid = false;
    }

    const sheetMetalDxfDwgCondition =
      sheetMetalDxfDwgType.includes(part?.cuttingType) &&
      partForm.getFieldValue("laserMarking") == true &&
      isSelectedLaserMarking == false &&
      !is2DFileExsit;

    const shetMetalStepCuttingType = part ? part.cuttingType : cuttingType;
    const shetMetalStepCondition =
      shetMetalStepCuttingType == CuttingType.SHEET_METAL_STP &&
      partForm.getFieldValue("laserMarking") == true &&
      !is2DFileExsit;

    const isTechnicalDrawingRequired =
      config.technicalDrawingRequired && !is2DFileExsit;

    const isPartNumberOfHolesRequired =
      part?.numberOfHoles != null &&
      part?.numberOfHoles > 0 &&
      !is2DFileExsit &&
      part?.customShapeType != ShapeTypes.ROUND;

    if (
      sheetMetalDxfDwgCondition ||
      shetMetalStepCondition ||
      isTechnicalDrawingRequired ||
      isPartNumberOfHolesRequired
    ) {
      setTechnicalDrawingError(t("required"));
      valid = false;
    }

    return partForm
      .validateFields()
      .then(() => {
        return valid;
      })
      .catch(() => {
        return false;
      });
  };

  const handlePartFormSubmit = async () => {
    const valid = await validatePartBeforeSubmit();
    if (valid == true) {
      partForm.submit();
    }
  };

  const handlePartFormDirectSubmit = async () => {
    const valid = await validatePartBeforeSubmit();
    if (valid == true) {
      setError(undefined);
      setParameterUploading(true);
      setIsDirectSubmit(true);
    }
  };

  useEffect(() => {
    if (uploaded && isDirectSubmit) {
      partForm.submit();
      setIsDirectSubmit(false);
    }
  }, [uploaded, isDirectSubmit]);

  useEffect(() => {
    setTechnicalDrawingError(null);
  }, [viewMode]);

  const renderDeburringText = () => {
    if (!part || !part.deburringType) {
      return;
    }

    const mapping = {
      [DeburringType.NO]: t("no"),
      [DeburringType.ONE_SIDED]: t("part.form.deburring.oneSided"),
      [DeburringType.TWO_SIDED]: t("part.form.deburring.twoSided"),
      [DeburringType.TROVALIZE]: t("part.form.deburring.trovalize"),
    };

    return mapping[part.deburringType];
  };

  const isCuttingTypeSheetMetalDxfDwg = sheetMetalDxfDwgType.includes(
    sheetMetalDxfDwgCuttingType
  );
  const isEditMode = viewMode === PartViewMode.EDIT;

  const QuantityInput = () => {
    return (
      <Form.Item
        label={<>{t("quantity")}</>}
        name="quantity"
        messageVariables={{
          label: t("quantity"),
        }}
        rules={[{ required: true }]}
      >
        <InputNumber type="number" min={1} />
      </Form.Item>
    );
  };

  if (deleted) return <></>;

  return (
    <>
      {contextHolder}
      <div
        {...viewProps}
        className={`card-part ${props.className ? props.className : ""}`}
      >
        <div className={`b-calculate ${parameterUploading && "active"}`}>
          <div className="text-center">
            <SettingOutlined style={{ fontSize: "4rem" }} spin />
            <p className="mt-2">{t("calculating")}...</p>
          </div>
        </div>

        <div className="row">
          {(uploading || part || error) && (
            <CadFileItemInfo
              partPreview={partPreview}
              part={part}
              uploading={uploading}
              cuttingType={cuttingType}
              file={file}
            />
          )}
          <div className="col part-info px-md-4">
            <Form
              form={partForm}
              layout="vertical"
              onFinish={updateParameters}
              onValuesChange={onPartChange}
              disabled={parameterUploading}
              initialValues={
                part || {
                  materialProvided: false,
                  techDrawingFile: null,
                  countersink: 0,
                  quantity: 1,
                  deburringType: DeburringType.NO,
                  laserMarking: false,
                  rollingDirection: RollingDirection.NO_PREFERENCE,
                  laserGas: LaserGas.O2,
                  numberOfThreads: 0,
                }
              }
            >
              <div
                className={`d-flex flex-column flex-md-row ${
                  technicalDrawingError ? "mb-1" : "mb-3"
                }`}
              >
                {auth.user?.role === UserRole.SELLER && !isEditMode && (
                  <CostReportModal
                    partId={part?.id}
                    projectId={projectId}
                    onAdapted={onPriceAdapted}
                    readonly={viewMode === PartViewMode.READ_ONLY}
                    currencyExchange={currencyInfo}
                  ></CostReportModal>
                )}

                {isEditMode && (
                  <div className="col flex-col">
                    <div className="row">
                      <div className="col col-12 col-md-6 col-lg-12">
                        <Upload {...upload2DProps} className="me-auto">
                          <div
                            className={
                              technicalDrawingError
                                ? "drawing-upload-error"
                                : ""
                            }
                          >
                            <Button
                              type="link"
                              icon={
                                file2D?.name || part?.pdfFileName ? (
                                  <PdfFileIcon width={16} height={16} />
                                ) : (
                                  <UploadOutlined />
                                )
                              }
                              loading={uploadingTechDrawingFile}
                              title={
                                file2D?.name ||
                                part?.pdfFileName ||
                                t("part.addDrawing") ||
                                ""
                              }
                              className="upload-button"
                            >
                              <label className="ms-2 text-ml-300px">
                                <span>
                                  {file2D?.name ||
                                    part?.pdfFileName ||
                                    t("part.addDrawing")}
                                </span>
                              </label>
                            </Button>
                            {technicalDrawingError && (
                              <div
                                className="pt-2"
                                role="alert"
                                style={{ maxWidth: 500 }}
                              >
                                <div className="helper-text">
                                  {technicalDrawingError}
                                </div>
                              </div>
                            )}
                          </div>
                        </Upload>
                      </div>
                    </div>
                  </div>
                )}

                {/* {showDeleteButton &&
                  isEditMode &&
                  (part?.unitPrice || !part?.auto) && (
                    <div className="d-flex">
                      <Form.Item className="mb-0 ms-3">
                        <Button
                          type="default"
                          icon={<DeleteOutlined />}
                          onClick={showDeleteModal}
                          danger
                        >
                          {t("delete")}
                        </Button>
                      </Form.Item>
                    </div>
                  )} */}

                {/* FOR DXF */}
                {sheetMetalDxfDwgType.includes(part?.cuttingType) && (
                  <>
                    {!showCalculationButton && !uploaded && (
                      <>
                        <Spin indicator={analyzingIcon} />
                        &nbsp;
                        <span className="text-primary fw-semibold">
                          {t("analyzing")}...
                        </span>
                      </>
                    )}
                    {showCalculationButton && (
                      <div className="d-flex">
                        {part?.material && (
                          <Form.Item className="mb-0 ms-3">
                            <Button type="default" onClick={onEditCancel}>
                              {t("cancel")}
                            </Button>
                          </Form.Item>
                        )}
                        <Form.Item className="mb-0 ms-3">
                          <Button
                            type="primary"
                            htmlType="button"
                            icon={<SettingOutlined spin={parameterUploading} />}
                            onClick={handlePartFormSubmit}
                          >
                            {t("calculate")}
                          </Button>
                        </Form.Item>
                      </div>
                    )}
                  </>
                )}
                {/* FOR DXF */}

                {!sheetMetalDxfDwgType.includes(part?.cuttingType) && (
                  <>
                    <div className="d-flex">
                      {showCalculationButton && part?.material && (
                        <Form.Item className="mb-0 ms-3">
                          <Button type="default" onClick={onEditCancel}>
                            {t("cancel")}
                          </Button>
                        </Form.Item>
                      )}
                      {viewMode == PartViewMode.EDIT && (
                        <Form.Item className="mb-0 ms-3">
                          <Button
                            type="primary"
                            htmlType="button"
                            icon={<SettingOutlined spin={parameterUploading} />}
                            onClick={handlePartFormDirectSubmit}
                          >
                            {t("calculate")}
                          </Button>
                        </Form.Item>
                      )}
                    </div>
                  </>
                )}

                {uploaded &&
                  viewMode !== PartViewMode.READ_ONLY &&
                  viewMode !== PartViewMode.EDIT &&
                  (viewMode !== PartViewMode.ADAPT ||
                    project?.selfCalculation) && (
                    <div className="ms-md-auto mb-3 mb-md-0">
                      <Button
                        className="ms-md-3"
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={onEdit}
                      >
                        {t("edit")}
                      </Button>
                    </div>
                  )}
                {showActionButton && (
                  <div className="ms-3" onClick={disableClickEvent}>
                    <DropdownActions
                      project={project}
                      part={part}
                      onSubmitted={onActionsSubmmitted}
                      hiddenViewBtn
                      hiddenDeleteBtn={false}
                      onDeleteClick={showDeleteModal}
                    />
                  </div>
                )}
              </div>
              {!(
                viewMode === PartViewMode.READ_ONLY &&
                auth.user?.role === UserRole.BUYER
              ) && <hr />}
              <AlertPartWarning part={part} />
              <AlertManualReason part={part} />
              {(!uploaded || viewMode === PartViewMode.EDIT) && (
                <>
                  <Form.Item name="techDrawingFile" className="mb-0" hidden>
                    <Input />
                  </Form.Item>
                  <div>
                    {error && (
                      <Alert
                        className="mb-3"
                        message={error.message}
                        type="error"
                        icon={<MinusCircleOutlined />}
                        showIcon
                      />
                    )}

                    <Form.Item name="partId" hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name="materialGroupId" hidden>
                      <Input />
                    </Form.Item>
                    {(!error || uploaded) && (
                      <>
                        <div className="row">
                          <>
                            <div className={`col col-12 col-md-3`}>
                              <div className="row">
                                <div className={`col`}>
                                  <Form.Item
                                    className="mb-0"
                                    label={t("material")}
                                    name="materialId"
                                    rules={[{ required: true }]}
                                  >
                                    <MaterialSelectModal
                                      mode={mode}
                                      cuttingType={
                                        cuttingType || part.cuttingType
                                      }
                                      part={part}
                                      dataPart={dataPart}
                                      onMaterialSelectedCallBack={
                                        onMaterialSelected
                                      }
                                      thickness={thickness}
                                    />
                                  </Form.Item>
                                  <div className="col col-12 col-md-12">
                                    <Form.Item
                                      className="mb-0"
                                      name="materialProvided"
                                      valuePropName="checked"
                                    >
                                      <Checkbox>
                                        {t("part.form.materialProvided")}
                                      </Checkbox>
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {isCuttingTypeSheetMetalDxfDwg && (
                              <div className="col col-12 col-md-2">
                                <Form.Item
                                  label={t("part.form.thickness")}
                                  name="thickness"
                                  rules={[{ required: true }]}
                                >
                                  <Select placeholder={t("pleaseSelect")}>
                                    {!material &&
                                      thicknesses?.map((t: any) => (
                                        <Option
                                          key={`thickness-${t}`}
                                          value={t}
                                        >
                                          {t} mm
                                        </Option>
                                      ))}
                                    {material &&
                                      material?.thicknesses?.map((t) => (
                                        <Option
                                          key={`material-thickness-${t.id}`}
                                          value={t.value}
                                        >
                                          {t.value} mm
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </div>
                            )}
                            {!isCuttingTypeSheetMetalDxfDwg && uploaded && (
                              <div className={`col col-12 col-md-2`}>
                                <Form.Item
                                  label={t("part.form.thickness")}
                                  tooltip={{
                                    title: t("part.thickness.auto"),
                                    icon: <InfoCircleOutlined />,
                                    trigger: "hover",
                                  }}
                                >
                                  {part ? (
                                    part?.thickness ? (
                                      `${part?.thickness} mm`
                                    ) : (
                                      "--"
                                    )
                                  ) : (
                                    <LoadingOutlined />
                                  )}
                                </Form.Item>
                              </div>
                            )}
                            {isCuttingTypeSheetMetalDxfDwg && (
                              <div className="col col-12 col-md-3 col-lg-3">
                                <Form.Item
                                  className="mb-0"
                                  name="dxfLayers"
                                  label={t("part.cuttingLayers")}
                                  rules={[{ required: true }]}
                                >
                                  <DxfSelectLayer
                                    part={part}
                                    selectedLayers={
                                      selectedLayers || part?.dxfLayers
                                    }
                                    onSelectedLayers={onSelectedLayers}
                                    onDxfLayerLoad={onDxfLayerLoad}
                                  />
                                </Form.Item>
                              </div>
                            )}
                            {isShowLaserGas && (
                              <div className="col col-12 col-md-6 col-lg-2">
                                <Form.Item
                                  className="mb-0"
                                  label={t("part.form.laserGas")}
                                  name="laserGas"
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    style={
                                      {
                                        // width: "120px",
                                      }
                                    }
                                  >
                                    <Option
                                      value={LaserGas.N2}
                                      disabled={
                                        (partForm?.getFieldValue("thickness") ||
                                          part?.thickness) > 8
                                      }
                                    >
                                      {t("part.laserGas.N2")}
                                    </Option>
                                    <Option value={LaserGas.O2}>
                                      {t("part.laserGas.O2")}
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            )}
                            <div className={`col col-12 col-md-2`}>
                              {QuantityInput()}
                            </div>
                          </>
                        </div>
                        <div>
                          <hr />
                          <div className="row">
                            <div className="col col-12 col-md-4 card-body-input-group">
                              <Card style={{ marginTop: "0.7rem" }}>
                                <div className="row">
                                  <label className="title text-label mb-1 card-title-absolute">
                                    {t("part.form.mechanicalTreatment")}
                                  </label>
                                  <div className="col col-6 col-md-6 col-lg-5">
                                    <Form.Item
                                      label={t("part.form.countersink")}
                                      name="countersink"
                                      rules={[{ required: true }]}
                                    >
                                      <InputNumber type="number" min={0} />
                                    </Form.Item>
                                  </div>
                                  <div className="col col-6 col-md-6 col-lg-7">
                                    <Form.Item
                                      label={t("part.form.threadCutting")}
                                      name="numberOfThreads"
                                      rules={[{ required: true }]}
                                    >
                                      <InputNumber type="number" min={0} />
                                    </Form.Item>
                                  </div>
                                </div>
                              </Card>
                            </div>
                            <div
                              className={`col col-12 col-md-6 card-body-input-group`}
                            >
                              <Card style={{ marginTop: "0.7rem" }}>
                                <div className="row">
                                  <label className="title text-label mb-1 card-title-absolute">
                                    {t("part.form.surfaceFinish")}
                                  </label>
                                  <div className="col col-4 col-md-4">
                                    <Form.Item
                                      label={t("part.form.deburring")}
                                      className="mb-0"
                                      name="deburringType"
                                    >
                                      <Select
                                        options={[
                                          {
                                            label: t("no"),
                                            value: DeburringType.NO,
                                          },
                                          {
                                            label: t(
                                              "part.form.deburring.oneSided"
                                            ),
                                            value: DeburringType.ONE_SIDED,
                                          },
                                          {
                                            label: t(
                                              "part.form.deburring.twoSided"
                                            ),
                                            value: DeburringType.TWO_SIDED,
                                          },
                                          {
                                            label: t(
                                              "part.form.deburring.trovalize"
                                            ),
                                            value: DeburringType.TROVALIZE,
                                          },
                                        ]}
                                      ></Select>
                                    </Form.Item>
                                  </div>
                                  <div className="col col-8 col-md-8">
                                    <Form.Item
                                      name="surfaceTreatmentIds"
                                      label={t("part.surfaceTreatments")}
                                    >
                                      <SurfaceTreatmentSelector
                                        surfaceTreatments={surfaceTreatments}
                                        material={material}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                              </Card>
                            </div>

                            {!isSelectedLaserMarking && (
                              <div className="col col-12 col-md-1 col-lg-2">
                                <Form.Item
                                  label={t("part.form.laserMarking")}
                                  className="mb-0"
                                  name="laserMarking"
                                >
                                  <Select
                                    options={[
                                      {
                                        label: t("part.form.laserMarking.no"),
                                        value: false,
                                      },
                                      {
                                        label: t("part.form.laserMarking.yes"),
                                        value: true,
                                      },
                                    ]}
                                  ></Select>
                                </Form.Item>
                              </div>
                            )}
                            <div className="col-12 mt-3">
                              <Form.Item
                                label={t("part.form.additionalComments")}
                                name="additionalComments"
                              >
                                <TextArea maxLength={255} />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </Form>
            {viewMode !== PartViewMode.EDIT && (
              <>
                <div className="">
                  <div
                    className={`row mb-3 ${
                      (!referenceCosts || !part?.auto) && "no-price-reference"
                    }`}
                  >
                    <div className="part-data col d-flex flex-wrap">
                      <div className="b-field">
                        <label>{t("material")}:</label>
                        <div className="d-flex">
                          {part?.material?.name}/
                          <MaterialNumber value={part?.material?.number} />
                          {part?.material?.link && (
                            <>
                              &nbsp;
                              <a
                                href={part.material.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="bi bi-box-arrow-up-right"></i>
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                      <div className={`b-field`}>
                        <label>{t("part.surfaceTreatments")}:</label>
                        <label className="d-table">
                          {!!part?.surfaceTreatments?.length
                            ? part?.surfaceTreatments.map((s) => (
                                <Tag
                                  key={`surface-${s.id}`}
                                  // color="#FEF2C3"
                                  className="mb-2 app-tag"
                                >
                                  <span className="text-black">
                                    <LocalizationName
                                      localizations={s?.localizations}
                                      valueDefault={s.method}
                                    />
                                  </span>
                                </Tag>
                              ))
                            : "None"}
                        </label>
                      </div>

                      {(isDxf(part.partFileName) ||
                        isDwg(part.partFileName)) && (
                        <div className="col d-flex flex-column b-field">
                          <label>{t("part.cuttingLayers")}:</label>
                          <label className="d-table">
                            {(!part?.dxfLayers ||
                              Object.keys(part?.dxfLayers).length === 0) && (
                              <Tag className="app-tag">
                                <span className="text-black">
                                  {t("part.allLayers")}
                                </span>
                              </Tag>
                            )}
                            {Object.keys(part?.dxfLayers || {})?.map((l, i) => (
                              <Tag
                                key={`${part.id}-layers-${i}`}
                                color="#FEF2C3"
                              >
                                <span className="text-black">{l}</span>
                              </Tag>
                            ))}
                          </label>
                        </div>
                      )}
                      {sheetMetalType.includes(part?.cuttingType) && (
                        <>
                          <div className="b-field">
                            <label>{t("part.form.deburring")}:</label>
                            <label>{renderDeburringText()}</label>
                          </div>
                          <div className="b-field">
                            <label>{t("part.form.countersink")}:</label>
                            <label>{part?.countersink}</label>
                          </div>
                          <div className="b-field">
                            <label>{t("part.form.threadCutting")}:</label>
                            <label>{part?.numberOfThreads}</label>
                          </div>
                          {sheetMetalType.includes(cuttingType) && (
                            <div className="b-field">
                              <label>{t("part.form.thickness")}:</label>
                              <label>{part?.thickness} mm</label>
                            </div>
                          )}
                          {(sheetMetalDxfDwgType.includes(part?.cuttingType) ||
                            part.cuttingType ==
                              CuttingType.SHEET_METAL_STP) && (
                            <>
                              <div className="b-field">
                                <label>{t("part.form.laserMarking")}:</label>
                                <label>
                                  {part?.laserMarking
                                    ? t("part.form.laserMarking.yes")
                                    : t("part.form.laserMarking.no")}
                                </label>
                              </div>
                            </>
                          )}
                          {isShowLaserGas && (
                            <div className="b-field">
                              <label>{t("part.form.laserGas")}:</label>
                              <label>
                                {part?.laserGas == LaserGas.N2
                                  ? t("part.laserGas.N2")
                                  : t("part.laserGas.O2")}
                              </label>
                            </div>
                          )}
                          {/* Placeholder */}
                          {isShowLaserGas && (
                            <>
                              <div className="b-field"></div>
                            </>
                          )}

                          {part?.nbFolds != undefined && part?.nbFolds > 0 && (
                            <div className="b-field">
                              <label>{t("part.form.folding")}:</label>
                              <label>
                                <Tag className="mb-2 app-tag">
                                  <span className="text-black">
                                    {part?.nbFolds}
                                  </span>
                                </Tag>
                              </label>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    {
                      <div className="ms-auto col-4 quantity-references">
                        <div>
                          {((viewMode === PartViewMode.ADAPT &&
                            project.selfCalculation) ||
                            viewMode === PartViewMode.UPDATED) &&
                            part.auto &&
                            referenceCosts && (
                              <div>
                                <PriceReference
                                  part={part}
                                  data={referenceCosts}
                                  layout="light"
                                  hasUpdateQuantity
                                  onQuantityChanged={onQuantityChanged}
                                />
                              </div>
                            )}
                        </div>
                      </div>
                    }
                  </div>
                  <hr />
                  <div>
                    <div className="row">
                      <div className="col col-12 col-md-6 d-flex flex-column b-field mb-1">
                        <label>{t("part.label.additionalComments")}:</label>
                        <label className="text-break">
                          {part?.additionalComments || (
                            <i className="text-secondary">{t("empty")}</i>
                          )}
                        </label>
                      </div>
                      <div className="col col-12 col-md-4 ms-auto">
                        <div className="row">
                          <div className="col col-12 d-flex align-items-center flex-row part-quantity-input mb-2">
                            <label className="text-label">
                              {t("quantity")}:
                            </label>
                            {(viewMode === PartViewMode.READ_ONLY ||
                              viewMode === PartViewMode.ADAPT ||
                              viewMode === PartViewMode.UPDATED) && (
                              <label
                                className="ms-auto text-end"
                                style={{ width: "70%" }}
                              >
                                {viewMode === PartViewMode.READ_ONLY && (
                                  <>{part?.quantity}</>
                                )}
                                {viewMode !== PartViewMode.READ_ONLY && (
                                  <InputNumberInline
                                    callBack={onFormQuantitySubmit}
                                    value={part?.quantity}
                                    name="quantity"
                                    label={t("quantity")}
                                    inputProps={{
                                      type: "number",
                                      onKeyDown: Utils.triggerTypeNumber,
                                      min: 1,
                                      max: 99999,
                                    }}
                                  />
                                )}
                              </label>
                            )}
                          </div>
                          <div
                            className={`col col-12 d-flex align-items-center mb-2 ${
                              pricePreview && "text-decoration-line-through"
                            }`}
                          >
                            <label className="mb-0 text-label">
                              {t("price.unitPrice")}:
                            </label>
                            {viewMode !== PartViewMode.ADAPT && (
                              <label className="ms-auto mb-0">
                                <CurrencyFormat
                                  value={part?.unitPrice}
                                  adaptPrice={part?.adaptedUnitPrice}
                                  isHideAdapt={
                                    (project?.selfCalculation &&
                                      (!auth.isLogined ||
                                        auth.user?.role === UserRole.BUYER)) ||
                                    (!project?.selfCalculation &&
                                      project?.manual)
                                  }
                                  hiddenValue={
                                    auth.user?.role === UserRole.BUYER &&
                                    !part?.auto &&
                                    (project?.status ===
                                      ProjectStatus.IN_PROGRESS ||
                                      project?.status ===
                                        ProjectStatus.REQUESTED_OFFER)
                                  }
                                  currencyExchange={currencyInfo}
                                />
                              </label>
                            )}
                            {viewMode === PartViewMode.ADAPT && (
                              <PriceAdaptForm
                                ref={adaptForm}
                                part={part}
                                onAdapted={onPriceAdapted}
                                className="app-form"
                              />
                            )}
                          </div>
                          {(pricePreviewLoading || pricePreview) && (
                            <div className="col col-12 d-flex align-items-center mb-2 text-primary">
                              <label className="mb-0 text-label">
                                {t("price.preview")}:
                              </label>
                              <label className="ms-auto mb-0 fw-bold">
                                {pricePreviewLoading ? (
                                  <LoadingOutlined />
                                ) : (
                                  <CurrencyFormat
                                    value={pricePreview}
                                    currencyExchange={currencyInfo}
                                  />
                                )}
                              </label>
                            </div>
                          )}
                          <div
                            className={`col col-12 d-flex align-items-center mb-1 ${
                              pricePreview && "text-decoration-line-through"
                            }`}
                          >
                            <label className="mb-0 text-label">
                              {t("price.netTotal")}:
                            </label>
                            <h6 className="ms-auto mb-0">
                              <CurrencyFormat
                                value={part?.unitPrice * part?.quantity}
                                adaptPrice={
                                  part?.adaptedUnitPrice != null
                                    ? part.totalPrice
                                    : undefined
                                }
                                isHideAdapt={
                                  part?.unitPrice == null ||
                                  (project?.selfCalculation &&
                                    (!auth.isLogined ||
                                      auth.user?.role === UserRole.BUYER)) ||
                                  (!project?.selfCalculation && project?.manual)
                                }
                                hiddenValue={
                                  (part?.auto && part?.unitPrice == null) ||
                                  (auth.user?.role === UserRole.BUYER &&
                                    !part?.auto &&
                                    (project?.status ===
                                      ProjectStatus.IN_PROGRESS ||
                                      project?.status ===
                                        ProjectStatus.REQUESTED_OFFER))
                                }
                                currencyExchange={currencyInfo}
                              />
                            </h6>
                          </div>
                          {(pricePreviewLoading || pricePreview) && (
                            <div className="col col-12 d-flex align-items-center mb-2 text-primary mb-0">
                              <label className="mb-0 text-label">
                                {t("price.netTotal")}:
                              </label>
                              <h6 className="ms-auto mb-0 fw-bold">
                                {pricePreviewLoading ? (
                                  <LoadingOutlined />
                                ) : (
                                  <CurrencyFormat
                                    value={(pricePreview || 0) * part.quantity}
                                    currencyExchange={currencyInfo}
                                  />
                                )}
                              </h6>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-part d-flex"></div>
              </>
            )}
          </div>
        </div>
      </div>
      <CadFileItemDeleteModal
        part={part}
        file={file}
        onDeletedCallBack={onDeletedCallBack}
        onCanceledCallBack={setOpenDelete.bind(null, false)}
        showDeleteModalProp={openDelete}
      />
    </>
  );
}

export default CadFileItemSheetMetal;
