export const machineConstants: any = {
  localizedName: "Name",
  hourlyRate: {
    label: "Stundensatz (Maschine + Personal)",
    description:
      "\u20AC/h: Stundensatz in der Kombination aus Maschine und Mitarbeiter",
    unit: "\u20AC/h",
  },
  maxRotation: {
    label: "Drehzahl (Max.)",
    description: "1/min.: Max. Drehzahl in 1/min.",
    unit: "1/min",
  },
  maxWeight: "Gewicht (Max.)",
  maxLength: {
    label: "Länge (Max.)",
    description: "mm: Maximale Länge des zu bearbeitenden Bauteils",
    unit: "mm",
  },
  maxWidth: {
    label: "Breite (Max.)",
    description: "mm: Maximale Breite des zu bearbeitenden Bauteils",
    unit: "mm",
  },
  maxHeight: {
    label: "Breite (Max.)",
    description: "mm: Maximale Breite des zu bearbeitenden Bauteils",
    unit: "mm",
  },
  maxDiameter: {
    label: "Durchmesser (Max.)",
    description: "mm: Maximaler Durchmesser des zu bearbeitenden Bauteils",
    unit: "mm",
  },
  factorSetupTime: {
    label: "Rüstzeit",
    description:
      "<b>Wert für die Kalibrierung der Rüstzeit von 0,01 bis 2,5: (0,01 bedeutet sehr kurz und 2,5 sehr lange Rüstzeit)</b><br>Wert \u201C0,5\u201D bedeutet:<br>Grundrüstzeit: 5 Min.<br>Pro Werkzeug: 0,5 Min.<br>Wert \u201C1,0\u201D bedeutet:  <br>Grundrüstzeit: 10 Min.<br>Pro Werkzeug: 1 Min.<br>Wert \u201C2,0\u201D bedeutet:  <br>Grundrüstzeit: 10 Min.<br>Pro Werkzeug: 1 Min.",
    // unit: "min",
  },
  factorAdditionalTime: {
    label: "Nebenzeit",
    description:
      "<b>Wert für die Kalibrierung der Nebenzeiten von 0,01 bis 2,5: (0,01 bedeutet sehr kurz und 2,5 sehr lange Nebenzeiten)</b>Wert \u201C0,5\u201D bedeutet:  Verfahrzeit von 0,02 Min.Werkzeugwechsel: 0,12 Min.<br>Be-und Entladezeit: 0,25 Min.Wert \u201C1,0\u201D bedeutet:  Verfahrzeit von 0,03 Min.Werkzeugwechsel: 0,25 Min.<br>Be-und Entladezeit: 0,5 Min.<br>Wert \u201C2,0\u201D bedeutet:  Verfahrzeit von 0,07 Min.Werkzeugwechsel: 0,5 Min<br>Be-und Entladezeit: 1 Min.",
    // unit: "min",
  },
  factorProgrammingTimes: {
    label: "Programmierzeiten",
    description:
      "<b>Wert für die Kalibrierung der Porgrammierzeiten von 0,01 bis 2,5: (0,01 bedeutet sehr kurz und 2,5 sehr lange Programmierzeiten)</b><br><br>Wert \u201C0,5\u201D bedeutet:  <br>Grundprogramm: 3,75 Min.<br>Au\u00DFenansatz: 0,38 Min.<br>Pro Bohrung: 0,38 Min.<br>Wert \u201C1,0\u201D bedeutet:  <br>Grundprogramm: 15 Min.<br>Au\u00DFenansatz: 1,5 Min.<br>Pro Bohrung: 1,5 Min.<br>Wert \u201C2\u201D bedeutet:  <br>Grundprogramm: 60 Min.<br>Au\u00DFenansatz: 6 Min.<br>Pro Bohrung: 6 Min.",
    // unit: "min",
  },
  "turningCuttingParams.factorFeed": {
    label: "Schnittwerte Drehen (Vorschub)",
    description:
      "<b>Wert für die Kalibrierung der Dreh Schneidwerte (Vorschub) von 0,01 bis 2,5: (0,01 bedeutet sehr langsame und 2,5 sehr schnelle Bearbeitung)</b><br>Wert \u201C0,5\u201D bedeutet:  <br>0,09 mm/U<br>Wert \u201C1,0\u201D bedeutet:  <br>0,18 mm/U<br>Wert \u201C2\u201D bedeutet:  <br>0,36 mm/U",
    // unit: "mm/U",
  },
  "turningCuttingParams.factorCuttingSpeed": {
    label: "Schnittwerte Drehen (Schnittgeschw.)",
    description:
      "<b>Wert für die Kalibrierung der Dreh Schneidwerte (Schnittgeschw.) von 0,01 bis 2,5: (0,01 bedeutet sehr langsame und 2,5 sehr schnelle Bearbeitung)</b><br>Wert \u201C0,5\u201D bedeutet:  <br>90 m/Min.<br>Wert \u201C1,0\u201D bedeutet:  <br>180 m/Min.<br>Wert \u201C2\u201D bedeutet:  <br>360 m/Min.",
    // unit: " m/Min",
  },
  "turningCuttingParams.factorPlungingDepth": {
    label: "Schnittwerte Drehen (Zustelltiefe)",
    description:
      "<b>Wert für die Kalibrierung der Dreh Schneidwerte (Zustelltiefe) von 0,01 bis 2,5: (0,01 bedeutet sehr langsame und 2,5 sehr schnelle Bearbeitung)</b><br>Wert \u201C0,5\u201D bedeutet:  <br>1 mm<br>Wert \u201C1,0\u201D bedeutet:  <br>2 mm<br>Wert \u201C2\u201D bedeutet:  <br>4 mm",
    // unit: "mm",
  },
  "cuttingParams.factorFeed": {
    label: "Schnittwerte Drehen (Vorschub)",
    description:
      "<b>Wert für die Kalibrierung der Dreh Schneidwerte (Vorschub) von 0,01 bis 2,5: (0,01 bedeutet sehr langsame und 2,5 sehr schnelle Bearbeitung)</b><br>Wert \u201C0,5\u201D bedeutet:  <br>0,09 mm/U<br>Wert \u201C1,0\u201D bedeutet:  <br>0,18 mm/U<br>Wert \u201C2\u201D bedeutet:  <br>0,36 mm/U",
    // unit: "mm/U",
  },
  "cuttingParams.factorCuttingSpeed": {
    label: "Schnittwerte Drehen (Schnittgeschw.)",
    description:
      "<b>Wert für die Kalibrierung der Dreh Schneidwerte (Schnittgeschw.) von 0,01 bis 2,5: (0,01 bedeutet sehr langsame und 2,5 sehr schnelle Bearbeitung)</b><br>Wert \u201C0,5\u201D bedeutet:  <br>90 m/Min.<br>Wert \u201C1,0\u201D bedeutet:  <br>180 m/Min.<br>Wert \u201C2\u201D bedeutet:  <br>360 m/Min.",
    // unit: " m/Min",
  },
  "cuttingParams.factorPlungingDepth": {
    label: "Schnittwerte Drehen (Zustelltiefe)",
    description:
      "<b>Wert für die Kalibrierung der Dreh Schneidwerte (Zustelltiefe) von 0,01 bis 2,5: (0,01 bedeutet sehr langsame und 2,5 sehr schnelle Bearbeitung)</b><br>Wert \u201C0,5\u201D bedeutet:  <br>1 mm<br>Wert \u201C1,0\u201D bedeutet:  <br>2 mm<br>Wert \u201C2\u201D bedeutet:  <br>4 mm",
    // unit: "mm",
  },
  "millingCuttingParams.factorFeed": {
    label: "Schnittwerte Fräsen(Vorschub)",
    description:
      "<b>Wert für die Kalibrierung der Fräs-Schneidwerte (Vorschub) von 0,01 bis 2,5: (0,01 bedeutet sehr langsame und 2,5 sehr schnelle Bearbeitung)</b><br>Wert \u201C0,5\u201D bedeutet:  <br>0,03 mm/Z<br>Wert \u201C1,0\u201D bedeutet:  <br>0,06 mm/Z<br>Wert \u201C2\u201D bedeutet:  <br>0,12 mm/Z",
    // unit: "mm/Z",
  },
  "millingCuttingParams.factorCuttingSpeed": {
    label: "Schnittwerte Fräsen (Schnittgeschw.)",
    description:
      "<b>Wert für die Kalibrierung der Fräs-Schneidwerte (Schnittgeschwindigkeit) von 0,01 bis 2,5: (0,01 bedeutet sehr langsame und 2,5 sehr schnelle Bearbeitung)</b><br>Wert \u201C0,5\u201D bedeutet:  <br>58,50 m/Min.<br>Wert \u201C1,0\u201D bedeutet:  <br>117 m/Min.<br>Wert \u201C2\u201D bedeutet:  <br>234 m/Min.",
    // unit: " m/Min",
  },
  "millingCuttingParams.factorPlungingDepth": {
    label: "Schnittwerte Fräsen (Zustelltiefe)",
    description:
      "<b>Wert für die Kalibrierung der Fräs-Schneidwerte (Zustelltiefe) von 0,01 bis 2,5: (0,01 bedeutet sehr langsame und 2,5 sehr schnelle Bearbeitung)</b><br>Wert \u201C0,5\u201D bedeutet:  <br>3 mm<br>Wert \u201C1,0\u201D bedeutet:  <br>6 mm<br>Wert \u201C2\u201D bedeutet:  <br>12 mm",
    // unit: "mm",
  },
  "variant1.active": {
    label: "Variante 1 (kleine Losgrö\u00DFe)",
    description:
      "Inaktive Maschinen werden bei der ersten Berechnungsvariante nicht berücksichtigt (diese Variante ist für kleine Losgrö\u00DFen optimiert)",
  },
  "variant1.priority": {
    label: "Variante 1 Priorität",
    description:
      "Für Variante 1 gilt: Je niedriger der Wert, desto höher die Priorität. Das System wählt geeignete Maschinen für ein bestimmtes Bauteil aus und verwendet dann die Maschine mit der höchsten Priorität für die Berechnungsvariante",
  },
  "variant2.active": {
    label: "Variante 2 (kleine Losgrö\u00DFe)",
    description:
      "Inaktive Maschinen werden bei der zweiten Berechnungsvariante nicht berücksichtigt (diese Variante ist für hohe Losgrö\u00DFen optimiert)",
  },
  "variant2.priority": {
    label: "Variante 2 Priorität",
    description:
      "Für Variante 2 gilt: Je niedriger der Wert, desto höher die Priorität. Das System wählt geeignete Maschinen für ein bestimmtes Bauteil aus und verwendet dann die Maschine mit der höchsten Priorität für die Berechnungsvariante",
  },
};
