import {
  Collapse,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Machine } from "../../models/Machine";
import { loadingActions } from "../../redux/slides/loading.slide";
import machineSettingServices from "../../services/machineSetting.service";
import { useTranslation } from "react-i18next";
import MachineForm from "../../components/Machine/MachineForm";
const { Panel } = Collapse;

interface DefaultProps {}

const MillingAndTurningMachineSettings = (props: DefaultProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [machines, setMachines] = useState<Machine[]>([]);
  const [archiveMachines, setArchiveMachines] = useState<Machine[]>([]);
  const [inarchiveMachines, setInarchiveMachines] = useState<Machine[]>([]);

  const getMachines = async () => {
    try {
      dispatch(loadingActions.show(true));
      const rs = await machineSettingServices.list();
      setMachines(rs);
    } catch (error) {}
    dispatch(loadingActions.show(false));
  };

  useEffect(() => {
    getMachines();
  }, []);

  useEffect(() => {
    if (machines.length > 0) {
      setArchiveMachines(
        machines.filter((m) => m.variant1.active || m.variant2.active)
      );
      setInarchiveMachines(
        machines.filter((m) => !m.variant1.active && !m.variant2.active)
      );
    }
  }, [machines]);

  return (
    <>
      {contextHolder}
      <h3>
        {t("machine.settings")} ({t("active")})
      </h3>
      <Collapse accordion expandIconPosition="end">
        {archiveMachines.map((item) => (
          <Panel
            header={`${item.id} - ${t(`machines.${item.id}`)}`}
            key={`machine-${item.id}`}
          >
            <MachineForm data={item} />
          </Panel>
        ))}
      </Collapse>
      <h3>
        {t("machine.settings")} ({t("inactive")})
      </h3>
      <Collapse accordion expandIconPosition="end">
        {inarchiveMachines.map((item) => (
          <Panel
            header={`${item.id} - ${item.localizedName}`}
            key={`machine-${item.id}`}
          >
            <MachineForm data={item} />
          </Panel>
        ))}
      </Collapse>
    </>
  );
};

export default MillingAndTurningMachineSettings;
