import { ShopMaterial } from "app/models/ShopMaterial";
import CalculationStep1 from "./CalculationStep1";
import CalculationStep1DxfConfig from "./CalculationStep1DxfConfig";
import CalculationStep1Plastx from "./CalculationStep1Plastx";
import { useSelector } from "react-redux";
import { configSelector } from "app/redux/slides/config.slide";

interface DefaultProps {
  onChange: any;
  onCuttingTypeChange: any;
  onGenerateDxf: any;
}

export default function CalculationStep1Switcher(props: DefaultProps) {
  const { onChange, onCuttingTypeChange, onGenerateDxf } = props;
  const config = useSelector(configSelector);

  const RenderSwitch = () => {
    if (config.shopMaterial == ShopMaterial.PLASTIC) {
      return (
        <CalculationStep1Plastx
          onChange={onChange}
          onCuttingTypeChange={onCuttingTypeChange}
          onGenerateDxf={onGenerateDxf}
        />
      );
    } else {
      return (
        <CalculationStep1
          onChange={onChange}
          onCuttingTypeChange={onCuttingTypeChange}
          onGenerateDxf={onGenerateDxf}
        />
      );
    }
  };

  return <>{RenderSwitch()}</>;
}
