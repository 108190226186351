import { ReactNode, useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import "../paymentModal.module.scss";
import { CreditCardOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { authSelector } from "app/redux/slides/auth.slide";
import { configSelector } from "app/redux/slides/config.slide";
import { UserRole } from "app/models/UserRole";
import { Project, ProjectStatus } from "app/models/Project";
import PaymentForm from "../PaymentForm";

interface DefaultProps {
  children?: ReactNode;
  project: Project;
  triggerShowModal?: boolean;
  hideButton?: boolean;
  onModalClose?: any;
  onComplete: any;
}

const ProjectListStripePaymentFormModal = (props: DefaultProps) => {
  const { project, triggerShowModal, hideButton, onModalClose, onComplete } =
    props;
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const auth = useSelector(authSelector);
  const config = useSelector(configSelector);

  useEffect(() => {
    if (triggerShowModal != undefined) {
      setIsOpen(triggerShowModal);
    }
  }, [triggerShowModal]);

  const handlePayLater = () => {
    setIsOpen(false);
    if (onModalClose) {
      onModalClose();
    }
  };

  const handleOnCancel = () => {
    setIsOpen(false);
    if (onModalClose) {
      onModalClose();
    }
  };

  const handleOnCompletePayment = () => {
    setIsOpen(false);
    if (onComplete) {
      onComplete();
    }
  };

  if (
    auth.user.role != UserRole.BUYER ||
    !config?.paymentEnabled ||
    project?.order?.paid ||
    (project?.status != ProjectStatus.SELLER_APPROVED && project?.status != ProjectStatus.BUYER_APPROVED)
  ) {
    return <></>;
  }

  return (
    <div className="">
      {/* <LoadingComponent loading={loading} /> */}
      {(!hideButton || hideButton == undefined) && (
        <Button type="primary" onClick={setIsOpen.bind(null, true)}>
          <CreditCardOutlined />
          {t("payment.pay")}
        </Button>
      )}
      <Modal
        open={isOpen}
        title={t("payment")}
        width={"60vw"}
        className="modal-body-scroll payment-modal"
        onCancel={handleOnCancel}
        destroyOnClose
        footer={null}
        bodyStyle={{ overflow: "auto" }}
        centered
      >
        <div
          className="d-flex flex-column align-items-center"
          style={{ minHeight: 780 }}
        >
          <PaymentForm
            project={project}
            onCompletePayment={handleOnCompletePayment}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ProjectListStripePaymentFormModal;
