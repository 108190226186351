import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { langSelector } from "app/redux/slides/locale.slide";
import {
  buildSurfaceTreatmentOption,
  customFilterSurfacetreatment,
} from "app/utils/Form";
import { SurfaceTreatment } from "app/models/SurfaceTreatment";
import { Select, SelectProps } from "antd";
import { useEffect, useState } from "react";

const { Option } = Select;

interface DefaultProps {
  surfaceTreatments: SurfaceTreatment[];
  material?: any;
  selectProps?: SelectProps;
}

type Props = DefaultProps & SelectProps<any>;

const SurfaceTreatmentSelector: React.FC<Props> = ({
  value,
  onChange,
  surfaceTreatments,
  material,
}) => {
  const { t } = useTranslation();
  const [surfaceTreatmentTexts, setSurfaceTreatmentTexts] = useState<any[]>([]);
  const lang: string = useSelector(langSelector);

  useEffect(() => {
    if (surfaceTreatments) {
      const texts = buildSurfaceTreatmentOption(surfaceTreatments, lang);
      setSurfaceTreatmentTexts(texts);
    } else {
      setSurfaceTreatmentTexts([]);
    }
  }, [surfaceTreatments]);

  return (
    <Select
      id="surfaceTreatmentIds"
      value={value}
      onChange={onChange}
      showSearch
      optionFilterProp="children"
      filterOption={customFilterSurfacetreatment}
      mode="multiple"
      placeholder={t("pleaseSelect")}
      style={{ width: "100%" }}
      disabled={!material}
    >
      {surfaceTreatments.map((item) => (
        <Option key={`surface-treatment-${item.id}`} value={item.id}>
          {surfaceTreatmentTexts[item.id]}
        </Option>
      ))}
    </Select>
  );
};

export default SurfaceTreatmentSelector;
