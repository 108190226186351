const logo = require(`../images/${process.env.REACT_APP_LOGO}.png`);
const doneIcon = require("../images/done.png");
const uploadFilesIcon = require("../images/upload-files-icon.png");
const cadMultipleFileIcon = require("../images/ic-cad-multiple.png");
const pdfMultipleFileIcon = require("../images/ic-pdf-multiple.png");

export const images = {
  logo,
  doneIcon,
  uploadFilesIcon,
  cadMultipleFileIcon,
  pdfMultipleFileIcon
};
