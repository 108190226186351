import { createSlice } from "@reduxjs/toolkit";
import userServices from "../../services/user.service";
import { loadingActions } from "./loading.slide";
import jwtDecode from "jwt-decode";
import { setAuthorization } from "../../services";
import { Login } from "../../models/Login";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLogined: false,
    jwtToken: undefined,
    user: undefined,
    lastAccess: "",
    errorMsg: "",
  },
  reducers: {
    login: (state, action) => {
      state.jwtToken = action.payload.jwtToken;
      state.user = action.payload;
      state.isLogined = true;
    },
    loginWithCookie: (state, action) => {
      let data: any = jwtDecode<Object>(action.payload);
      data = { ...data, jwtToken: action.payload };
      state.jwtToken = data.jwtToken;
      state.user = data;
      state.isLogined = true;
    },
    loginError: (state) => {
      state.isLogined = false;
      state.jwtToken = undefined;
      state.user = undefined;
      state.errorMsg = "Falscher Benutzername oder falsches Passwort!";
    },
    clear: (state) => {
      state.isLogined = false;
      state.jwtToken = undefined;
      state.user = undefined;
      state.errorMsg = "";
    },
    setLastAccess: (state, action) => {
      state.lastAccess = action.payload;
    },
  },
});

export const authActions = authSlice.actions,
  { login, clear, loginError } = authSlice.actions;

export const loginAsync =
  ({ username, password, captchaResponse }: Login): any =>
  async (dispatch: any) => {
    dispatch(loadingActions.show(true));
    dispatch(clear());
    try {
      const rs = await userServices.login({
        username,
        password,
        captchaResponse,
      });
      let data = jwtDecode<Object>(rs.jwtToken);
      data = { ...data, jwtToken: rs.jwtToken };
      setAuthorization(rs.jwtToken);
      dispatch(login(data));
    } catch (error) {
      dispatch(loginError());
    }
    dispatch(loadingActions.show(false));
  };

export const authSelector = (state: any) => state.auth;

export default authSlice.reducer;
