import services from ".";
import { API } from "../constants/api.constant";

export const createCheckOutSession = async (id: any, redirectUrl: any) => {
  try {
    const rs = await services.post(
      API.PAYMENT_CREATE_SESSION.replace(":id", id),
      { redirectUrl }
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const paymentServices = {
  createCheckOutSession,
};

export default paymentServices;
