import { Alert, Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { routers } from "../../constants/router.constant";
import { ResetPassword } from "../../models/ResetPassword";
import { loadingActions } from "../../redux/slides/loading.slide";
import userServices from "../../services/user.service";
import { useTranslation } from "react-i18next";
import { useRulePassword } from "app/utils/FormValidation";

function ResetPasswordForm() {
  let [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rulePassword = useRulePassword();
  const [password, setPassword] = useState("");
  const [error, setError] = useState<any>();

  useEffect(() => {}, []);

  const onResetPassword = async (values: any) => {
    setError(undefined);
    const code: any = searchParams.get("code");
    const data: ResetPassword = {
      password: values.password,
      verificationCode: code,
    };
    dispatch(loadingActions.show(true));
    try {
      await userServices.resetPassword(data);
      navigate(routers.HOME);
    } catch (e: any) {
      if (e)
        e.message = e.message == "INVALID_VERIFICATION_CODE" ? t("error.resetPassword.invalidCode") : t("error.default");
      setError(e);
    }
    dispatch(loadingActions.show(false));
  };

  return (
    <div>
      <h4>{t("resetPassword")}</h4>
      <div className="row">
        <div className="col col-12 col-md-8">
          <Form
            name="basic"
            layout="vertical"
            onFinish={onResetPassword}
            autoComplete="off"
          >
            {error && (
              <Alert
                className="my-2"
                showIcon
                message={error.message}
                type="error"
              />
            )}
            <Form.Item
              label={t("password")}
              name="password"
              rules={rulePassword}
            >
              <Input.Password
                autoComplete="new-password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label={t("confirmPassword")}
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  validator: async (_, confirmPassword) => {
                    if (password != confirmPassword) {
                      return Promise.reject(
                        new Error(t("validate.confirmPassword") || "")
                      );
                    }
                    return true;
                  },
                },
              ]}
            >
              <Input.Password autoComplete="new-confpassword" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordForm;
