/* eslint-disable no-new-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { UploadFile } from "antd";
import { RcFile } from "antd/lib/upload/interface";
import { ShopType } from "app/models/ShopType";
import { configSelector } from "app/redux/slides/config.slide";
import { HTMLAttributes, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CuttingType } from "../../models/CuttingType";
import { MaterialGroup } from "../../models/MaterialGroup";
import { Part } from "../../models/Part";
import { PartViewMode } from "../../models/PartViewMode";
import { Project } from "../../models/Project";
import { materialSelector } from "../../redux/slides/material.slide";
import {
  projectActions,
  projectSelector,
} from "../../redux/slides/project.slide";
import CadFileItemMilledAndTurned from "./CadFileItemMilledAndTurned";
import CadFileItemSheetMetal from "./CadFileItemSheetMetal";
import "./cadFileItem.module.scss";
import CadFileItemTechnicalPlastics from "./CadFileItemTechnicalPlastics";
import CadFileItemRubber from "./CadFileItemRubber";
import CadFileItemStepRubber from "./CadFileItemStepRubber";
import Utils from "app/utils";
import CadFileItemDxfDwgRubber from "./CadFileItemDxfDwgRubber";
import { ShopMaterial } from "app/models/ShopMaterial";
import CadFileItemStepPlastic from "./CadFileItemStepPlastic";
import CadFileItemDxfDwgPlastic from "./CadFileItemDxfDwgPlastic";
interface DefaultProps extends HTMLAttributes<any> {
  file?: UploadFile<any>;
  pdfFile?: RcFile;
  type?: CuttingType;
  part?: Part;
  mode?: PartViewMode;
  pricePreview?: number;
  pricePreviewLoading?: boolean;
  onChange?: any;
  onQuantityChange?: any;
  onPartUploadedChange?: any;
  onPartRender?: any;
  onDeletedPart?: any;
}

function CadFileItem(props: DefaultProps) {
  // const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const config = useSelector(configSelector);
  const { t } = useTranslation();
  const { data } = useSelector(projectSelector);
  const {
    type,
    file,
    pdfFile,
    mode = PartViewMode.EDIT,
    pricePreview,
    pricePreviewLoading,
    onChange,
    onQuantityChange,
    onPartUploadedChange,
    onPartRender,
    part,
    onDeletedPart,
    ...viewProps
  } = props;

  let dataCuttingType =
    props.type ||
    (config.shopType === ShopType.BOTH ||
    config.shopType === ShopType.MILLING_AND_TURNING
      ? CuttingType.MILLING_AND_TURNING
      : CuttingType.SHEET_METAL);

  if (config.shopMaterial == ShopMaterial.PLASTIC && Utils.isStp(file?.name)) {
    dataCuttingType = CuttingType.MILLING_AND_TURNING;
  }

  const [cuttingType] = useState(dataCuttingType);
  const { groups, sheetMetalGroups } = useSelector(materialSelector);
  const materialGroups: MaterialGroup[] =
    cuttingType === CuttingType.MILLING_AND_TURNING ? groups : sheetMetalGroups;

  const [project] = useState<Project>(data);

  if (!file && !part) {
    return <></>;
  }

  const [viewMode, setViewMode] = useState<PartViewMode>(
    mode ||
      ((!!file || (part && part.totalPrice === null && part.auto)) &&
        PartViewMode.EDIT) ||
      (part &&
        (part.totalPrice !== null || !part?.auto) &&
        (project?.selfCalculation
          ? PartViewMode.ADAPT
          : PartViewMode.UPDATED)) ||
      PartViewMode.EDIT
  );

  useEffect(() => {
    if (part) {
      dispatch(projectActions.setPartStatus({ id: part.id, data: viewMode }));
    }
  }, [viewMode]);

  useEffect(() => {
    if (project?.archived == true) {
      setViewMode(PartViewMode.READ_ONLY);
    }
  }, [project?.archived]);

  const cuttingTypeSheetMetal = [
    CuttingType.SHEET_METAL,
    CuttingType.SHEET_METAL_STP,
    CuttingType.SHEET_METAL_DXF,
    CuttingType.SHEET_METAL_DWG,
  ];

  const cuttingTypeDxfDwg = [
    CuttingType.SHEET_METAL_DXF,
    CuttingType.SHEET_METAL_DWG,
  ];

  const isCuttingTypeSheetMetal =
    cuttingTypeSheetMetal.includes(cuttingType) ||
    (part?.cuttingType && cuttingTypeSheetMetal.includes(part?.cuttingType));

  const isSheetMetalDxf = Utils.isDxf(file?.name) && isCuttingTypeSheetMetal;
  const isSheetMetalDwg = Utils.isDwg(file?.name) && isCuttingTypeSheetMetal;

  const isCuttingTypeSheetMetalDxfDwg =
    isSheetMetalDxf ||
    isSheetMetalDwg ||
    (part?.cuttingType && cuttingTypeDxfDwg.includes(part?.cuttingType));

  const isCuttingTypeSheetMetalStep =
    (cuttingType === CuttingType.SHEET_METAL && Utils.isStp(file?.name)) ||
    part?.cuttingType === CuttingType.SHEET_METAL_STP;

  const isCuttingTypeMilling =
    cuttingType === CuttingType.MILLING_AND_TURNING ||
    part?.cuttingType === CuttingType.MILLING_AND_TURNING;

  const isTechnicalPlastics = !!process.env.REACT_APP_TECHNICAL_PLASTICS;

  const isSheetMetalConfigurate = part?.customShapeType != null;

  // Rubber Shop Config
  const isRubberShop = config.shopMaterial == ShopMaterial.RUBBER;
  const isDxfConfigRubberPart = isRubberShop && isSheetMetalConfigurate;
  const isStepRubberPart = isRubberShop && isCuttingTypeSheetMetalStep;
  const isDxfDwgRubberPart = isRubberShop && isCuttingTypeSheetMetalDxfDwg;

  const isPlasticShop = config.shopMaterial == ShopMaterial.PLASTIC;
  const isDxfConfigPlasticPart = isPlasticShop && isSheetMetalConfigurate;
  const isStepPlasticPart = isPlasticShop && isCuttingTypeMilling;
  const isDxfDwgPlasticPart = isPlasticShop && isCuttingTypeSheetMetalDxfDwg;

  const handleAssemblyPartUpload = (part: Part) => {
    if (onPartUploadedChange) {
      if (part) {
        onPartUploadedChange({ ...part, fileUUID: file?.uid });
        return;
      }
      onPartUploadedChange(part);
    }
  };

  const handleOnDeletedPart = (part: Part) => {
    if (onDeletedPart) {
      onDeletedPart(part);
    }
  };

  const RenderCadFile = () => {
    if (isDxfConfigRubberPart || isDxfConfigPlasticPart) {
      return (
        <CadFileItemRubber
          {...viewProps}
          file={file}
          pdfFile={pdfFile}
          type={type}
          part={part}
          mode={mode}
          pricePreview={pricePreview}
          pricePreviewLoading={pricePreviewLoading}
          onChange={onChange}
          onQuantityChange={onQuantityChange}
          onPartUploadedChange={handleAssemblyPartUpload}
          onPartRender={onPartRender}
          materialGroups={materialGroups}
          dataCuttingType={dataCuttingType}
          onDeletedPart={handleOnDeletedPart}
        />
      );
    } else if (isDxfDwgPlasticPart) {
      return (
        <CadFileItemDxfDwgPlastic
          {...viewProps}
          file={file}
          pdfFile={pdfFile}
          type={type}
          part={part}
          mode={mode}
          pricePreview={pricePreview}
          pricePreviewLoading={pricePreviewLoading}
          onChange={onChange}
          onQuantityChange={onQuantityChange}
          onPartUploadedChange={handleAssemblyPartUpload}
          onPartRender={onPartRender}
          materialGroups={materialGroups}
          dataCuttingType={dataCuttingType}
          onDeletedPart={handleOnDeletedPart}
        />
      );
    } else if (isStepPlasticPart) {
      return (
        <CadFileItemStepPlastic
          {...viewProps}
          file={file}
          pdfFile={pdfFile}
          type={type}
          part={part}
          mode={mode}
          pricePreview={pricePreview}
          pricePreviewLoading={pricePreviewLoading}
          onChange={onChange}
          onQuantityChange={onQuantityChange}
          onPartUploadedChange={handleAssemblyPartUpload}
          onPartRender={onPartRender}
          materialGroups={materialGroups}
          dataCuttingType={dataCuttingType}
          onDeletedPart={handleOnDeletedPart}
        />
      );
    } else if (isDxfDwgRubberPart) {
      return (
        <CadFileItemDxfDwgRubber
          {...viewProps}
          file={file}
          pdfFile={pdfFile}
          type={type}
          part={part}
          mode={mode}
          pricePreview={pricePreview}
          pricePreviewLoading={pricePreviewLoading}
          onChange={onChange}
          onQuantityChange={onQuantityChange}
          onPartUploadedChange={handleAssemblyPartUpload}
          onPartRender={onPartRender}
          materialGroups={materialGroups}
          dataCuttingType={dataCuttingType}
          onDeletedPart={handleOnDeletedPart}
        />
      );
    } else if (isStepRubberPart) {
      return (
        <CadFileItemStepRubber
          {...viewProps}
          file={file}
          pdfFile={pdfFile}
          type={type}
          part={part}
          mode={mode}
          pricePreview={pricePreview}
          pricePreviewLoading={pricePreviewLoading}
          onChange={onChange}
          onQuantityChange={onQuantityChange}
          onPartUploadedChange={handleAssemblyPartUpload}
          onPartRender={onPartRender}
          materialGroups={materialGroups}
          dataCuttingType={dataCuttingType}
          onDeletedPart={handleOnDeletedPart}
        />
      );
    } else if (
      isCuttingTypeMilling &&
      !isTechnicalPlastics &&
      !isCuttingTypeSheetMetal &&
      !isDxfDwgPlasticPart &&
      !isStepPlasticPart &&
      !isDxfDwgRubberPart &&
      !isStepRubberPart
    ) {
      return (
        <CadFileItemMilledAndTurned
          {...viewProps}
          file={file}
          pdfFile={pdfFile}
          type={type}
          part={part}
          mode={mode}
          pricePreview={pricePreview}
          pricePreviewLoading={pricePreviewLoading}
          onChange={onChange}
          onQuantityChange={onQuantityChange}
          onPartUploadedChange={handleAssemblyPartUpload}
          onPartRender={onPartRender}
          materialGroups={materialGroups}
          dataCuttingType={dataCuttingType}
          onDeletedPart={handleOnDeletedPart}
        />
      );
    } else if (
      isCuttingTypeSheetMetal &&
      !isTechnicalPlastics &&
      !isDxfDwgPlasticPart &&
      !isStepPlasticPart &&
      !isDxfDwgRubberPart &&
      !isStepRubberPart
    ) {
      return (
        <CadFileItemSheetMetal
          {...viewProps}
          file={file}
          pdfFile={pdfFile}
          type={type}
          part={part}
          mode={mode}
          pricePreview={pricePreview}
          pricePreviewLoading={pricePreviewLoading}
          onChange={onChange}
          onQuantityChange={onQuantityChange}
          onPartUploadedChange={handleAssemblyPartUpload}
          onPartRender={onPartRender}
          materialGroups={materialGroups}
          dataCuttingType={dataCuttingType}
          onDeletedPart={handleOnDeletedPart}
        />
      );
    } else if (
      isCuttingTypeMilling &&
      isTechnicalPlastics &&
      !isCuttingTypeSheetMetal &&
      !isDxfDwgPlasticPart &&
      !isStepPlasticPart &&
      !isDxfDwgRubberPart &&
      !isStepRubberPart
    ) {
      return (
        <CadFileItemTechnicalPlastics
          {...viewProps}
          file={file}
          pdfFile={pdfFile}
          type={type}
          part={part}
          mode={mode}
          pricePreview={pricePreview}
          pricePreviewLoading={pricePreviewLoading}
          onChange={onChange}
          onQuantityChange={onQuantityChange}
          onPartUploadedChange={handleAssemblyPartUpload}
          onPartRender={onPartRender}
          materialGroups={materialGroups}
          dataCuttingType={dataCuttingType}
          onDeletedPart={handleOnDeletedPart}
        />
      );
    }
  };

  return <>{RenderCadFile()}</>;
}

export default CadFileItem;
