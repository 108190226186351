import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { langSelector } from "app/redux/slides/locale.slide";
import {
  buildSurfaceTreatmentOption,
  customFilterSurfacetreatment,
} from "app/utils/Form";
import { SurfaceTreatment } from "app/models/SurfaceTreatment";
import { Form, FormInstance, FormProps, Select, SelectProps } from "antd";
import { useEffect, useState } from "react";
import { Compliance } from "app/models/Compliance";
import { materialSelector } from "app/redux/slides/material.slide";

const { Option } = Select;

interface DefaultProps {
  form: FormInstance<any>;
  material?: any;
  selectProps?: SelectProps;
}

type Props = DefaultProps & SelectProps<any>;

const ComplianceSelector: React.FC<Props> = ({ form, onChange, material }) => {
  const { t } = useTranslation();
  const { compliances } = useSelector(materialSelector);
  const [dataCompliances, setDataCompliances] = useState([]);
  useEffect(() => {
    if (material) {
      const compliancesIds: any[] = material.compliances.map((c: any) => c.id);
      setDataCompliances(
        compliances.filter((c: any) => !compliancesIds.includes(c.id))
      );
    } else {
      setDataCompliances(compliances);
    }
  }, [compliances, material]);
  useEffect(() => {
    form.setFieldValue("requestedComplianceIds", []);
  }, [material]);
  return (
    <Form.Item
      label={t("part.furtherConfirmitiesRequested")}
      name="requestedComplianceIds"
    >
      <Select
        showSearch
        optionFilterProp="children"
        mode="multiple"
        placeholder={t("pleaseSelect")}
      >
        {dataCompliances.map((c: any) => (
          <Option key={`compliance-${c.id}`} value={c.id}>
            {c.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default ComplianceSelector;
