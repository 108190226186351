import { useTranslation } from "react-i18next";
import "./locale.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { langSelector, localeActions } from "../../redux/slides/locale.slide";
import i18next, { use } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { authSelector } from "app/redux/slides/auth.slide";
import userServices from "app/services/user.service";
import { profileSelector } from "app/redux/slides/user.slide";
import { Dropdown, MenuProps } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { configSelector } from "app/redux/slides/config.slide";
import ENIcon from "../SVGs/ENIcon";
import DEIcon from "../SVGs/DEIcon";
import ITIcon from "../SVGs/ITIcon";
import CZIcon from "../SVGs/CZIcon";
import { setLocale } from "yup";
import { de, it, cs, en } from "yup-locales";

const locales: any = {
  en: {
    key: "en",
    label: "English",
    icon: <ENIcon />,
  },
  de: {
    key: "de",
    label: "German",
    icon: <DEIcon />,
  },
  it: {
    key: "it",
    label: "Italian",
    icon: <ITIcon />,
  },
  cs: {
    key: "cs",
    label: "Czech",
    icon: <CZIcon />,
  },
};

const LocaleButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const user = useSelector(profileSelector);
  const lang = useSelector(langSelector);
  const config = useSelector(configSelector);
  // const locales = config.locales || [];
  const [items, setItems] = useState<any>(
    config.locales?.map((l: any) => locales[l]) || []
  );

  const langChange = async (key: string) => {
    if (!key) return;
    moment.locale(key);
    await i18next.changeLanguage(key);
    dispatch(localeActions.setLang(key));
    if (auth.isLogined && user && key != user?.locale) {
      userServices.changeLanguage(key.toUpperCase());
    }
  };

  useEffect(() => {
    setItems(config.locales?.map((l: any) => locales[l]));
  }, [config]);

  useEffect(() => {
    if (auth.isLogined && user) {
      if (!user.locale) {
        userServices.changeLanguage(lang.toUpperCase());
      } else {
        langChange(user.locale.toLowerCase());
      }
    } else if (config.locales && config.locales.length > 0) {
      langChange(config.locales[0]);
    }
  }, [user, config]);

  useEffect(() => {
    if (lang) {
      switch (lang) {
        case "de":
          setLocale(de);
          break;
        case "it":
          setLocale(it);
          break;
        case "cs":
          setLocale(cs);
          break;
        default:
          setLocale(en);
          break;
      }
    }
  }, [lang]);

  const onMenuCLick = ({ key }: any) => {
    langChange(key);
  };

  return (
    <div className="locale">
      <Dropdown
        menu={{ items, onClick: onMenuCLick, className: "dropdown-locales" }}
        placement={auth.isLogined ? "topLeft" : "bottomLeft"}
      >
        <a onClick={(e) => e.preventDefault()}>
          <button className="btn">
            {lang === "de" && <DEIcon width={"2.4rem"} />}
            {lang === "en" && <ENIcon width={"2.4rem"} />}
            {lang === "it" && <ITIcon width={"2.4rem"} />}
            {lang === "cs" && <CZIcon width={"2.4rem"} />}
          </button>
        </a>
      </Dropdown>
    </div>
  );
};

export default LocaleButton;
