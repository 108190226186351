import {
    ClockCircleOutlined,
    CheckOutlined,
    CloseCircleOutlined,
} from "@ant-design/icons";
import { HTMLAttributes } from "react";
import { Tag } from "antd";
import { ProjectStatus } from "../../models/Project";
import { useTranslation } from "react-i18next";
import "./projectStatusTag.module.scss";
import { ArrowDownUp, FileEarmarkText } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/slides/auth.slide";
import { UserRole } from "app/models/UserRole";

interface DefaultProps extends HTMLAttributes<any> {
  status: ProjectStatus;
}

function ProjectStatusTag(props: DefaultProps) {
  const { t } = useTranslation();
  const auth = useSelector(authSelector);
  const role = auth.user?.role == UserRole.BUYER ? "buyer." : "seller.";
  const { status, ...tagProps } = props;
  return (
    <>
      {status === ProjectStatus.IN_PROGRESS && (
        <Tag
          {...tagProps}
          className={`project-status ${props.className}`}
          icon={<FileEarmarkText className="me-2" />}
          color="processing"
        >
          {t(`project.status.${role}${status}`)}
        </Tag>
      )}
      {status === ProjectStatus.REQUESTED_OFFER && (
        <Tag
          {...tagProps}
          className={`project-status  ${props.className}`}
          icon={<ClockCircleOutlined />}
          color="warning"
        >
          {t(`project.status.${role}${status}`)}
        </Tag>
      )}
      {status === ProjectStatus.CHECKOUTED && (
        <Tag
          {...tagProps}
          className={`project-status ${props.className}`}
          icon={<ClockCircleOutlined />}
          color="warning"
        >
          {t(`project.status.${role}${status}`)}
        </Tag>
      )}
      {status === ProjectStatus.PRICE_ADAPTED && (
        <Tag
          {...tagProps}
          className={`project-status ${props.className}`}
          icon={<ArrowDownUp className="me-2" />}
          color="processing"
        >
          {t(`project.status.${role}${status}`)}
        </Tag>
      )}
      {(status === ProjectStatus.SELLER_REJECTED ||
        status === ProjectStatus.BUYER_REJECTED) && (
        // <Tooltip
        //   placement="top"
        //   title={t(`project.status.seller.${status}.note`)}
        // >
          <Tag
            {...tagProps}
            className={`project-status ${props.className}`}
            icon={<CloseCircleOutlined />}
            color="volcano"
          >
            {t(`project.status.${role}${status}`)}
          </Tag>
        // {/* </Tooltip> */}
      )}
      {(status === ProjectStatus.BUYER_APPROVED ||
        status === ProjectStatus.SELLER_APPROVED) && (
        // <Tooltip
        //   placement="top"
        //   title={t(`project.status.seller.${status}.note`)}
        // >
          <Tag
            {...tagProps}
            className={`project-status ${props.className}`}
            icon={<CheckOutlined />}
            color="success"
          >
            {t(`project.status.${role}${status}`)}
          </Tag>
        // </Tooltip>
      )}
    </>
  );
}
export default ProjectStatusTag;
