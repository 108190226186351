import { DownOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { HTMLAttributes } from "react";

interface DefaultProps extends HTMLAttributes<any> {
  tooltip?: any;
}
const ButtonSelect = (props: DefaultProps) => {
  const { tooltip, ...viewProps } = props;
  return tooltip ? (
    <Tooltip placement="top" title={tooltip}>
      <a {...viewProps} className="btn-select" onClick={props.onClick}>
        <label>{props.children}</label>
        <DownOutlined />
      </a>
    </Tooltip>
  ) : (
    <a {...viewProps} className="btn-select" onClick={props.onClick}>
      <label>{props.children}</label>
      <DownOutlined />
    </a>
  );
};

export default ButtonSelect;
