import services from ".";
import { API } from "../constants/api.constant";
import { TableFilter } from "../models/Table";

export const list = async (params?: TableFilter) => {
  try {
    const rs = await services.get(API.CUSTOMER_OVERVIEW);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const detail = async (id: number) => {
  try {
    const rs = await services.get(
      API.CUSTOMER_OVERVIEW_BY_ID.replace(":id", id.toString())
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const update = async (data: any) => {
  try {
    const rs = await services.post(API.CUSTOMER_OVERVIEW, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const activeUser = async (id: number) => {
  try {
    const rs = await services.post(
      API.USER_MANUAL_VERIFY.replace(":id", id.toString())
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const customerServices = {
  list,
  detail,
  update,
  activeUser,
};

export default customerServices;
