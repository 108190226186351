import AppBrowserRouter from "app/components/App/AppBrowserRouter";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import i18n from "./app/locales/i18n";
import { store } from "./app/redux/store";
import reportWebVitals from "./reportWebVitals";
import(`./assets/styles/theme.${process.env.REACT_APP_BRAND}.scss`);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const persistor = persistStore(store);
root.render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <I18nextProvider i18n={i18n}>
            <AppBrowserRouter>
              <App />
            </AppBrowserRouter>
          </I18nextProvider>
      </PersistGate>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
