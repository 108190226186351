import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { Price } from "app/models/Price";
import { QuickSetting } from "app/models/QuickSetting";
import quickSettingsServices from "app/services/quickSetting.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CurrencyFormat from "../Format/CurrencyFormat";
import { useSelector } from "react-redux";
import { currencySelector } from "app/redux/slides/user.slide";
import { CurrencyExchange } from "app/models/User";
import { configSelector } from "app/redux/slides/config.slide";
interface DefaultProps {
  price?: Price;
  lableClass?: string;
  netPriceSurchargeHidden?: boolean;
  currencyExchange?: CurrencyExchange;
}

function TotalPartPrice(props: DefaultProps) {
  const {
    price,
    lableClass,
    netPriceSurchargeHidden = false,
    currencyExchange,
  } = props;
  const { t } = useTranslation();
  const [quickSetting, setQuickSetting] = useState<QuickSetting>();
  const config = useSelector(configSelector);
  const currency = useSelector(currencySelector);

  // const getQuickSetting = async () => {
  //   try {
  //     const rs = await quickSettingsServices.detail();
  //     setQuickSetting(rs);
  //   } catch (error) {}
  // };

  useEffect(() => {
    // getQuickSetting();
  }, []);

  return (
    <>
      {!!price?.netPriceSurcharge && !netPriceSurchargeHidden && (
        <div className="d-flex">
          <label className={lableClass ?? lableClass}>
            {t("price.netPriceSurcharge")}{" "}
            <Popover
              placement="right"
              content={t("price.netPriceSurcharge.desc", {
                minimumOrderValue: config?.minimumOrderValue,
                minimumOrderValueForFastDelivery:
                config?.minimumOrderValueForFastDelivery,
                symbol: currency.symbol,
              })}
              trigger="hover"
            >
              <InfoCircleOutlined />
            </Popover>
            :
          </label>
          <label className="ms-auto">
            <CurrencyFormat
              value={price?.netPriceSurcharge}
              currencyExchange={currencyExchange}
            />
          </label>
        </div>
      )}
      <div className="d-flex">
        <label className={lableClass ?? lableClass}>
          {t("price.totalPartPrice")}:
        </label>
        <label className="ms-auto">
          <CurrencyFormat
            value={price?.totalPartPrice}
            currencyExchange={currencyExchange}
          />
        </label>
      </div>
    </>
  );
}

export default TotalPartPrice;
