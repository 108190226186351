import { Modal, UploadFile } from "antd";
import { Part } from "app/models/Part";
import { projectActions } from "app/redux/slides/project.slide";
import partServices from "app/services/part.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

interface DefaultProps {
  part?: Part;
  file?: UploadFile<any>;
  onDeletedCallBack: any;
  onCanceledCallBack?: any;
  showDeleteModalProp: boolean;
}

function CadFileItemDeleteModal(props: DefaultProps) {
  const { part, file, onDeletedCallBack, showDeleteModalProp, onCanceledCallBack } = props;
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setOpenDelete(showDeleteModalProp);
  }, [showDeleteModalProp]);

  const cancelDeleteModal = () => {
    setOpenDelete(false);
    if (onCanceledCallBack) {
      onCanceledCallBack();
    }
  };

  const onDeletePart = async () => {
    setDeleting(true);
    try {
      if (part) {
        await partServices.deletePart(part?.id);
        dispatch(projectActions.deletePart(part?.id));
      }
      if (file) {
        dispatch(projectActions.deleteUploadPart(file.uid));
      }
      setOpenDelete(false);
      onDeletedCallBack(true);
    } catch (error) {}
    setDeleting(false);
  };

  return (
    <Modal
      open={openDelete}
      title={`${part?.partFileName || file?.name || part?.name}`}
      onCancel={cancelDeleteModal}
      onOk={onDeletePart}
      cancelButtonProps={{
        disabled: deleting,
      }}
      okButtonProps={{
        loading: deleting,
      }}
    >
      {t("part.delete.title", {
        name: part?.partFileName || file?.name ||  part?.name,
      })}
    </Modal>
  );
}

export default CadFileItemDeleteModal;
